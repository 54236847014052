import * as THREE from 'three';
import Level from '../level.js';
import Scoring from '../components/scoring.js';
import * as CANNON from 'cannon-es';
import Destroyable from '../utils/destroyable';
import { getPolyhedronShape } from '../utils/meshToConvex.js';
//import { getPolyhedronShape } from '../utils/meshToConvex';

export default class CryptoGate extends Destroyable {
    constructor(levelName) {
        super(levelName);
        this.level = new Level();
        this.scoring = new Scoring();
        this.scene = this.level.scene;
        this.world = this.level.world.world;
        //this.environment = this.level.world.environment;
        this.resources = this.level.resources;
        //this.time = this.level.time

        this.createGate();

        this.touchGateBound = this.touchGate.bind(this);
        this.gateBody.addEventListener('collide', this.touchGateBound);
    }

    createGate() {
        const boxGeometry = new THREE.BoxGeometry(3.7, 5, 2.4);
        const material = new THREE.MeshBasicMaterial({ color: 0xbb1111 });
        const boundaryBox = new THREE.Mesh(boxGeometry, material);
        boundaryBox.position.set(0, 0, -11.2);
        // this.scene.add(boundaryBox);

        const terminalModel = this.resources.items.terminal;
        this.terminal = terminalModel.scene;
        this.scene.add(this.terminal);
        console.log(this.terminal);
        this.terminal.castShadow = true;
        this.terminal.receiveShadow = true;
        this.terminal.position.set(0, 0, 0);

        let collisionConvexShape = getPolyhedronShape(boundaryBox);
        const boundaryConvexBody = new CANNON.Body({
            mass: 0,
        });

        boundaryConvexBody.addShape(collisionConvexShape);
        boundaryConvexBody.position.set(boundaryBox.position.x, boundaryBox.position.y, boundaryBox.position.z);
        boundaryConvexBody.quaternion.copy(boundaryBox.quaternion);
        this.world.addBody(boundaryConvexBody);

        this.gateBody = boundaryConvexBody;
        this.gateMesh = boundaryBox;
    }

    removeGate() {
        this.world.removeBody(this.gateBody);
        this.scene.remove(this.gateMesh);
        this.scene.remove(this.terminal);
        this.gateMesh.geometry.dispose();
        // this.terminal.geometry.dispose();
        this.gateMesh.material.dispose();
        // this.terminal.material.dispose();
        this.gateMesh = undefined;
        this.terminal = undefined;
    }

    touchGate(e) {
        if (e.body == this.level.world.protagonist.sphereBody) {
            //this.openConsole();
            this.scoring.startedCryptoChallenge();
            // only needed once
            this.gateBody.removeEventListener('collide', this.touchGateBound);
        }
    }
}
