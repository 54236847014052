import Alpine from 'alpinejs';
import { getLevelData, closePanelSequence } from './utils/panelProcessor.js';
import { evaluateEnteredPassword } from './utils/passwordConsolePanel.js';
import Game from './game.js';
import { initAnalytics, initAnalyticsSingleOrg } from './analytics.js';
import './main.css';

/* UI panel handling */
window.getLevelData = getLevelData;
window.closePanelSequence = closePanelSequence;
window.evaluateEnteredPassword = evaluateEnteredPassword;

//window.getFeedbackData = getFeedbackData;

/*Attaching Alpine to window for capturing events and to use dev toolbar*/
window.Alpine = Alpine;

Alpine.start();

document.addEventListener('DOMContentLoaded', () => {
    if (document.getElementById('game')) {
        new Game(document.getElementById('game'));
    }
});

initAnalytics();
initAnalyticsSingleOrg();
