import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';

import EventEmitter from './eventEmitter.js';

export default class Resources extends EventEmitter {
    constructor(sources, levelName) {
        super();
        // Options

        this.textureSources = this.prepareElementList(sources, 'textures');

        this.modelSources = this.prepareElementList(sources, 'models');

        this.audioSources = this.prepareElementList(sources, 'audio');
        //this.textSources = []

        if (levelName == 'behaviour') {
            this.textSources = this.prepareTextContent(sources, 'behaviourObelisks');
        } else if (levelName == 'multifactor') {
            this.textSources = this.prepareTextContent(sources, 'factorsAntennas');
        } else if (levelName == 'crypto') {
            this.textSources = this.prepareTextContent(sources, 'passwordFeedback');
        }

        // Setup
        this.items = {};
        this.texturesModelsToLoadCount = Object.keys(this.textureSources).length + Object.keys(this.modelSources).length;

        this.texturesModelsLoadedCount = 0;

        this.setLoaders();
        /*Add models*/
        this.loadTexturesModels();
    }

    /*EXTEND TO MODEL LOADING*/
    prepareElementList(allSources, elementType) {
        let textureDict = {};
        for (let index in allSources) {
            let item = allSources[index];
            for (let key in item) {
                if (item[key] === elementType) {
                    for (let texture in item['data']) {
                        textureDict[item['data'][texture].name] = item['data'][texture].path;
                    }
                }
            }
        }
        return textureDict;
    }

    setLoaders() {
        this.loaders = {};
        this.loaders.gltfLoader = new GLTFLoader();
        this.loaders.textureLoader = new THREE.TextureLoader();
        this.loaders.audioLoader = new THREE.AudioLoader();
    }

    loadTexturesModels() {
        if (this.textureSources) {
            for (const value in this.textureSources) {
                this.loaders.textureLoader.load(this.textureSources[value], (file) => {
                    this.sourceLoaded(value, file);
                });
            }
        }

        if (this.modelSources) {
            for (const value in this.modelSources) {
                this.loaders.gltfLoader.load(this.modelSources[value], (file) => {
                    this.sourceLoaded(value, file);
                });
            }
        }
        if (!this.textureSources && !this.modelSources) {
            // no textures or models to load, wouldn't happen otherwise
            this.trigger('resources_ready');
        }
    }

    loadAudio(listener) {
        if (this.audioSources) {
            for (const value in this.audioSources) {
                this.loaders.audioLoader.load(
                    this.audioSources[value],
                    (audioBuffer) => {
                        // set the audio object buffer to the loaded object
                        let audioSource = new THREE.Audio(listener);
                        audioSource.setBuffer(audioBuffer);

                        // play the audio
                        audioSource.play();
                    },

                    // onProgress callback
                    function (xhr) {
                        console.log((xhr.loaded / xhr.total) * 100 + '% loaded');
                    },

                    // onError callback
                    function (err) {
                        console.log('An error happened');
                        console.log(err);
                    }
                );
            }
        }
    }

    sourceLoaded(source, file) {
        this.items[source] = file;

        this.texturesModelsLoadedCount++;

        if (this.texturesModelsToLoadCount === this.texturesModelsLoadedCount) {
            this.trigger('resources_ready');
        }
    }

    prepareTextContent(allSources, elementType) {
        let textDict = {};
        for (let index in allSources) {
            let item = allSources[index];
            for (let key in item) {
                if (item[key] === elementType) {
                    for (let textItem in item['data']) {
                        /*for text dict need to create a list*/
                        textDict[item['data'][textItem].id] = item['data'][textItem];
                        //textDict[item['data'][textItem].id].push(item['data'][textItem].content);
                        //textDict[item['data'][textItem].id].push(item['data'][textItem].good);
                    }
                }
            }
        }
        return textDict;
    }

    getLabelText(itemId) {
        /*first item being text content*/
        return this.textSources[itemId];
    }
}
