import { Vector3 } from 'three';

const distanceThreshold = 10;
const radius = 45; // space has a radius of 50, but we don't want them right up against the wall probably
const centre = new Vector3(0, 0, 0);

function generatePositions(numberToCreate, positionY) {
    let existingPositions = [];
    //first index so we can compare at all
    existingPositions.push(getNewPosition(positionY));

    while (existingPositions.length < numberToCreate) {
        let randomPosition = getNewPosition(positionY);

        if (!checkIfOverlaps(randomPosition, existingPositions)) {
            existingPositions.push(randomPosition);
        }
    }

    return existingPositions;
}
function getNewPosition(posY) {
    let proportionRotate = Math.random();
    let thisDistanceThreshold = distanceThreshold;
    if (proportionRotate > 0.24 && proportionRotate < 0.26) {
        // don't place obelisks in flashlight immediately
        thisDistanceThreshold = 30;
    }
    let posTheta = proportionRotate * 2 * Math.PI;
    let posR = Math.random() * (radius - thisDistanceThreshold) + thisDistanceThreshold;
    let p = new Vector3(posR * Math.cos(posTheta), posY, posR * Math.sin(posTheta)).add(centre);
    return p;
}

function checkIfOverlaps(randomPosition, existingPositions) {
    let overlaps = false;

    for (let posIndex = 0; posIndex < existingPositions.length; posIndex++) {
        let distanceBetween = existingPositions[posIndex].distanceTo(randomPosition);

        if (distanceBetween <= distanceThreshold) {
            overlaps = true;
        }
    }

    return overlaps;
}

export { generatePositions, getNewPosition };
