import * as THREE from 'three';

import Game from '../game.js';
import Level from '../level.js';

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';

export default class Camera {
    constructor() {
        this.game = new Game();
        this.canvas = this.game.canvas;
        this.sizes = this.game.sizes;

        this.level = new Level();
        this.scene = this.level.scene;

        this.setInstance();
    }

    setInstance() {
        this.instance = new THREE.PerspectiveCamera(45, this.sizes.width / this.sizes.height, 0.1, 120);
        this.instance.position.set(0, 5, 10);
        this.scene.add(this.instance);
    }

    setControls() {
        this.controls = new OrbitControls(this.instance, this.canvas);
        this.controls.enableDamping = true;
    }

    resize() {
        this.instance.aspect = this.sizes.width / this.sizes.height;
        this.instance.updateProjectionMatrix();
    }

    update() {
        //this.controls.update();
    }
}
