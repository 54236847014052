import Chart from 'chart.js/auto';

// global state seems to be unavoidable here
window.chartorg = '';

function updateChartOrg(name) {
    let deptcharts = document.getElementsByClassName('dept-chart');
    for (let elem of deptcharts) {
        elem.style.display = 'none';
    }
    let namedcharts = document.getElementsByClassName(name);
    for (let elem of namedcharts) {
        elem.style.display = '';
    }
}

function footer(tooltipItems) {
    const employeeId = tooltipItems[0].parsed.x;
    const gamesCompleted = tooltipItems[0].parsed.y;
    return `Employee #${employeeId} has completed ${gamesCompleted}/3 games.`;
}

function createScatterChart(ctx, data, kind, name) {
    new Chart(ctx, {
        type: 'scatter',
        data: data[kind][name],
        options: {
            plugins: {
                title: {
                    display: true,
                    text: name,
                },
                tooltip: {
                    callbacks: {
                        footer: footer,
                    },
                },
            },
            responsive: true,
            animation: false,
            scales: {
                y: {
                    ticks: {
                        stepSize: 1,
                    },
                    title: {
                        display: true,
                        text: 'Games',
                    },
                },
                x: {
                    ticks: {
                        stepSize: 1,
                    },
                    type: 'linear',
                    position: 'bottom',
                    beginAtZero: false,
                    min: 1,
                    title: {
                        display: true,
                        text: 'Employees',
                    },
                },
            },
        },
    });
}

function createBarChart(ctx, data, kind, name, xOrY) {
    const chartOptions = {
        indexAxis: 'y',
        responsive: true,
        animation: false,
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        plugins: {
            legend: {
                position: 'right',
            },
        },
    };

    if (xOrY === 'x') {
        chartOptions['indexAxis'] = 'x';
        chartOptions['scales'] = {
            yAxis: {
                suggestedMax: 100,
                ticks: {
                    callback: function (value, _index, _values) {
                        return value + '%';
                    },
                },
            },
        };
        chartOptions['onClick'] = function (evt, barItem) {
            if (barItem.length != 0) {
                updateChartOrg(data[kind][name]['labels'][barItem[0].index]);
            }
        };
    }

    new Chart(ctx, {
        type: 'bar',
        data: data[kind][name],
        options: chartOptions,
    });
}

export function logGenericAnalytic(kind) {
    const data = {
        level: kind,
    };
    return fetch('/report/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': CSRFTOKEN,
        },
        body: JSON.stringify(data),
    });
}

export function logLevelAnalytic(levelName, isLevelSuccessful) {
    const data = {
        level: levelName,
        success: isLevelSuccessful,
    };
    return fetch('/report/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': CSRFTOKEN,
        },
        body: JSON.stringify(data),
    });
}

export function initAnalytics() {
    document.addEventListener('DOMContentLoaded', function (evt) {
        if (!document.getElementById('show-charts')) {
            // return if not on the chart template
            return;
        }
        return fetch('/dashboard/admin/analytics/chartdata/')
            .then((resp) => resp.json())
            .then((data) => {
                Object.keys(data).forEach((kind) => {
                    const section = document.getElementById(`${kind}-charts`);
                    Object.keys(data[kind]).forEach((name) => {
                        if (name === 'overall') {
                            const ctx = document.createElement('canvas');
                            section.insertBefore(ctx, section.firstChild);
                            createBarChart(ctx, data, kind, name, 'x');
                        } else if (kind === 'risk') {
                            const wrapper = document.createElement('div');
                            wrapper.id = name + '-risk';
                            wrapper.classList.add('risk-chart');
                            wrapper.style.display = 'none';
                            wrapper.style.marginTop = '16px';
                            const ctx = document.createElement('canvas');
                            wrapper.appendChild(ctx);
                            section.appendChild(wrapper);
                            createScatterChart(ctx, data, kind, name);
                        } else {
                            const wrapper = document.createElement('div');
                            wrapper.classList.add(name);
                            wrapper.classList.add('dept-chart');
                            wrapper.style.display = 'none';
                            const title = document.createElement('p');
                            title.classList.add('font-bold');
                            title.style.marginTop = '16px';
                            title.style.marginBottom = '8px';
                            title.innerHTML = name;
                            const ctx = document.createElement('canvas');
                            wrapper.appendChild(title);
                            wrapper.appendChild(ctx);
                            section.appendChild(wrapper);
                            createBarChart(ctx, data, kind, name, 'y');
                        }
                    });
                });
                return data;
            })
            .then((data) => {
                // build risk button for all orgs
                const riskCharts = document.getElementById('risk-charts-filters');
                for (let orgName in data['risk']) {
                    const orgFilterButton = document.createElement('div');
                    orgFilterButton.style.display = 'inline-block';
                    orgFilterButton.style.background = 'aliceblue';
                    orgFilterButton.style.marginTop = '8px';
                    orgFilterButton.style.marginRight = '8px';
                    orgFilterButton.style.cursor = 'pointer';
                    orgFilterButton.innerText = orgName;
                    orgFilterButton.addEventListener('click', (evt) => {
                        // hide all risk charts
                        const riskChartElemList = document.querySelectorAll('.risk-chart');
                        for (const elem of riskChartElemList) {
                            elem.style.display = 'none';
                        }

                        // show clicked risk chart
                        const chartWrapperElem = document.getElementById(orgName + '-risk');
                        chartWrapperElem.style.display = 'block';
                    });
                    riskCharts.appendChild(orgFilterButton);
                }
            });
    });
}

export function initAnalyticsSingleOrg() {
    document.addEventListener('DOMContentLoaded', function (evt) {
        if (!document.getElementById('show-charts-manager')) {
            // return if not on the chart template
            return;
        }
        return fetch(`/dashboard/manager/analytics/chartdata/`)
            .then((resp) => resp.json())
            .then((data) => {
                Object.keys(data).forEach((kind) => {
                    const section = document.getElementById(`${kind}-charts`);
                    Object.keys(data[kind]).forEach((name) => {
                        if (name === 'overall') {
                            const ctx = document.createElement('canvas');
                            section.insertBefore(ctx, section.firstChild);
                            createBarChart(ctx, data, kind, name, 'x');
                        } else if (kind === 'risk-org') {
                            const wrapper = document.createElement('div');
                            wrapper.id = name + '-risk-org';
                            wrapper.classList.add(name + '-risk-org');
                            wrapper.classList.add('risk-org-chart');
                            const ctx = document.createElement('canvas');
                            wrapper.appendChild(ctx);
                            section.appendChild(wrapper);
                            createScatterChart(ctx, data, kind, name);
                        } else if (kind === 'risk') {
                            const wrapper = document.createElement('div');
                            wrapper.id = name + '-risk';
                            wrapper.style.display = 'none';
                            wrapper.classList.add(name + '-risk');
                            wrapper.classList.add('risk-chart');
                            const ctx = document.createElement('canvas');
                            wrapper.appendChild(ctx);
                            section.appendChild(wrapper);
                            createScatterChart(ctx, data, kind, name);
                        } else {
                            const wrapper = document.createElement('div');
                            wrapper.classList.add(name);
                            wrapper.classList.add('dept-chart');
                            wrapper.style.display = 'none';
                            const title = document.createElement('p');
                            title.classList.add('font-bold');
                            title.style.marginTop = '16px';
                            title.style.marginBottom = '8px';
                            title.innerHTML = name;
                            const ctx = document.createElement('canvas');
                            wrapper.appendChild(title);
                            wrapper.appendChild(ctx);
                            section.appendChild(wrapper);
                            createBarChart(ctx, data, kind, name, 'y');
                        }
                    });
                });
                return data;
            })
            .then((data) => {
                // build risk button for all departments of a single org
                const riskCharts = document.getElementById('risk-charts-department-filters');
                riskCharts.style.marginBottom = '16px';
                for (let depName in data['risk']) {
                    const orgFilterButton = document.createElement('div');
                    orgFilterButton.style.display = 'inline-block';
                    orgFilterButton.style.background = 'aliceblue';
                    orgFilterButton.style.marginTop = '8px';
                    orgFilterButton.style.marginRight = '8px';
                    orgFilterButton.style.cursor = 'pointer';
                    orgFilterButton.innerText = depName;
                    orgFilterButton.addEventListener('click', (evt) => {
                        // hide all risk charts
                        const riskChartElemList = document.querySelectorAll('.risk-chart');
                        for (const elem of riskChartElemList) {
                            elem.style.display = 'none';
                        }

                        // show clicked risk chart
                        const chartWrapperElem = document.getElementById(depName + '-risk');
                        chartWrapperElem.style.display = 'block';
                    });
                    riskCharts.appendChild(orgFilterButton);
                }
            });
    });
}
