import * as THREE from 'three';

import * as CANNON from 'cannon-es';
// import CannonDebugger from 'cannon-es-debugger';

import Level from '../level.js';
import Protagonist from './protagonist.js';
import Environment from './environment.js';
import Lights from './lights.js';

import Floor from './floor.js';
import { DestroyableEventEmitter } from '../utils/destroyable.js';
import Obelisk from './obelisk.js';
import Flashlight from './flashlight';
import Antenna from './antenna.js';
import CryptoGate from './cryptoGate.js';
import SpiderShadow from './spiderShadow.js';

export default class World extends DestroyableEventEmitter {
    constructor(levelName) {
        super(levelName);

        //this.clock = new THREE.Clock();
        //this.levelName = levelName
        this.worldLoaded = false;
        this.level = new Level();
        this.scene = this.level.scene;

        this.time = this.level.time;
        this.scoring = this.level.scoring;

        this.world = new CANNON.World({
            gravity: new CANNON.Vec3(0, -19.82, 0), // m/s²
        });
        this.world.defaultContactMaterial.restitution = 0.1;

        /*        this.world.defaultContactMaterial.contactEquationStiffness = 5e6
        this.world.defaultContactMaterial.contactEquationRelaxation = 3*/
        this.world.broadphase = new CANNON.SAPBroadphase(this.world);
        //this.world.defaultContactMaterial.contactEquationStiffness = 1e6;
        // this.cannonDebugger = new CannonDebugger(this.scene, this.world);

        this.lights = new Lights();

        this.resources = this.level.resources;

        this.pointingAtObject = false;
        this.currentlyPointedAt = [];

        this.physicsActive = false;

        /*syncing timer counter with physics which operate using its own update&step
         * https://pmndrs.github.io/cannon-es/docs/classes/World.html#fixedStep
         *  */
        this.level.scoring.on('unpaused_timer', () => {
            this.physicsActive = true;
        });
        this.level.scoring.on('paused_timer', () => {
            this.physicsActive = false;
        });

        // Wait for resources
        this.resourcesReady = () => {
            // Setup with assets
            this.environment = new Environment(levelName);
            this.floor = new Floor();
            this.protagonist = new Protagonist(levelName);

            this.spider = new SpiderShadow();

            if (levelName === 'behaviour') {
                this.obelisks = new Obelisk(levelName);
                this.obeliskMeshes = this.obelisks.obelisksMeshes;
                this.flashlight = new Flashlight();
            } else if (levelName === 'multifactor') {
                this.antennas = new Antenna(levelName);
                this.antennaMeshes = this.antennas.antennaMeshes;
                this.flashlight = new Flashlight();
            } else if (levelName === 'crypto') {
                this.cryptoGate = new CryptoGate();
            }
            this.protagonistMesh = this.protagonist.mesh;
            this.exiting = false;

            this.protagonist.sphereBody.addEventListener('collide', (event) => {
                const { body } = event;
                if (body.isTrigger && !this.exiting) {
                    /*argument passing to trigger needs to be an array*/
                    const levelName = [body.userData.level];
                    this.exiting = true;
                    this.trigger('exiting_level', levelName);
                }
            });

            /*
             * raycast target area
             */
            if (levelName === 'behaviour' || levelName === 'multifactor') {
                this.raycaster = new THREE.Raycaster();
                this.intersects = null;
                this.direction = new THREE.Vector3();
                this.far = new THREE.Vector3();

                this.objDestin = new THREE.LineSegments(
                    new THREE.EdgesGeometry(new THREE.SphereGeometry(1, 8, 4)),
                    new THREE.LineBasicMaterial({
                        color: 0xffffff,
                        opacity: 0,
                        transparent: true,
                        linewidth: 1,
                    })
                );

                this.scene.add(this.objDestin);
                this.globalTargetPosition = new THREE.Vector3();
                this.level.camera.instance.attach(this.objDestin);
                this.objDestin.position.set(0, 11, -45);
            }
            this.worldLoaded = true;
            this.trigger('world_ready');
        };
        this.resources.on('resources_ready', this.resourcesReady);

        this.tick = () => {
            if (this.worldLoaded) {
                // Run the simulation independently of framerate every 1 / 60 ms

                if (this.physicsActive) this.world.fixedStep();
                //this.world.fixedStep();
                //this.world.step(0);

                // render collision preview
                // this.cannonDebugger.update();

                if (levelName === 'behaviour' || levelName === 'multifactor') {
                    this.objDestin.getWorldPosition(this.globalTargetPosition);

                    this.raycaster.set(this.protagonistMesh.position, this.direction.subVectors(this.globalTargetPosition, this.protagonistMesh.position).normalize());
                    this.raycaster.far = this.far.subVectors(this.globalTargetPosition, this.protagonistMesh.position).length(); // comment this line to have an infinite ray
                    if (levelName === 'behaviour') {
                        this.intersects = this.raycaster.intersectObjects(this.obeliskMeshes);
                    } else if (levelName === 'multifactor') {
                        this.intersects = this.raycaster.intersectObjects(this.antennaMeshes);
                    }

                    /* Intersects with a mesh object */
                    if (this.intersects.length > 0) {
                        if (!this.pointingAtObject) {
                            this.pointingAtObject = true;
                            this.currentlyPointedAt.push(this.intersects[0].object);

                            if (levelName === 'behaviour') {
                                this.trigger('intersected_obelisk', this.currentlyPointedAt);
                            } else if (levelName === 'multifactor') {
                                this.trigger('intersected_antenna', this.currentlyPointedAt);
                            }
                            this.currentlyPointedAt.pop();
                        }
                    } else {
                        if (this.pointingAtObject) {
                            this.pointingAtObject = false;
                            if (levelName == 'behaviour') {
                                this.trigger('stopped_intersecting_obelisk');
                            } else if (levelName == 'multifactor') {
                                this.trigger('stopped_intersecting_antenna');
                            }
                        }
                    }
                }
            }
        };
        this.time.on('tick', this.tick);
    }

    destroy() {
        this.resources.specificCallbackOff('resources_ready', this.resourcesReady);
        delete this.resourcesReady;
        this.time.specificCallbackOff('tick', this.tick);
        delete this.tick;
    }
}
