export default [
    {
        type: 'textures',
        data: [
            {
                name: 'sphereTexture',
                path: '/static/textures/protagonist.png',
            },
            {
                name: 'floorTexture',
                path: '/static/textures/floor_texture.jpg',
            },
            {
                name: 'glassTexture',
                path: '/static/textures/glass_texture.png',
            },
            {
                name: 'spiderTexture',
                path: '/static/textures/spider_provisional.jpg',
            },
        ],
    },
    {
        type: 'audio',
        data: [
            {
                name: 'positiveBlip',
                path: '/static/audio/positive_blip.mp3',
            },
        ],
    },
    {
        type: 'models',
        data: [
            {
                name: 'mainframe',
                path: '/static/models/mainframe.gltf',
            },
            {
                name: 'spider',
                path: '/static/models/Spider_rigged_3.glb',
            },
        ],
    },
];
/*  {
        name: 'sphereTexture',
        type: 'texture',
        path: 'static/textures/purple_texture.jpg',
      },
      {
        name: 'floorTexture',
        type: 'texture',
        path: 'static/textures/floor_texture.jpg',
      },
      {
        name: 'glassTexture',
        type: 'texture',
        path: 'static/textures/glass_texture.png',
      },*/

/*  {
  name: 'positiveBlip',
  type: 'audio',
  path: 'static/audio/positive_blip.mp3',
},*/
