import EventEmitter from './eventEmitter.js';

export default class Destroyable {
    static registeredDestroyables = {};
    constructor(label) {
        if (!Destroyable.registeredDestroyables[label]) {
            Destroyable.registeredDestroyables[label] = [];
        }
        Destroyable.registeredDestroyables[label].push(this);
    }
    destroy() {
        // destroy should be implemented on all destroyables to clear any references to itself or other destroyables it created
    }
    static destroyAll(label) {
        Destroyable.registeredDestroyables[label].forEach((destroyable) => {
            destroyable.destroy();
            for (const key in destroyable) {
                if (destroyable[key] instanceof Destroyable || destroyable[key] instanceof DestroyableEventEmitter) {
                    delete destroyable[key];
                }
            }
        });
        Destroyable.registeredDestroyables[label] = [];
    }
}

// an eventEmittter that registers itself as a Destroyable
export class DestroyableEventEmitter extends EventEmitter {
    constructor(label) {
        super();
        if (!Destroyable.registeredDestroyables[label]) {
            Destroyable.registeredDestroyables[label] = [];
        }
        Destroyable.registeredDestroyables[label].push(this);
    }
    destroy() {}
}
