export default [
    {
        type: 'textures',
        data: [
            {
                name: 1,
                path: '/static/textures/factors/1-password.png',
            },
            {
                name: 2,
                path: '/static/textures/factors/2-question.png',
            },
            {
                name: 3,
                path: '/static/textures/factors/3-pin.png',
            },
            {
                name: 4,
                path: '/static/textures/factors/4-card.png',
            },
            {
                name: 5,
                path: '/static/textures/factors/5-smartphone.png',
            },
            {
                name: 6,
                path: '/static/textures/factors/6-token.png',
            },
            {
                name: 7,
                path: '/static/textures/factors/7-facial-recognition.png',
            },
            {
                name: 8,
                path: '/static/textures/factors/8-fingerprint.png',
            },
            {
                name: 9,
                path: '/static/textures/factors/9-retinal-scan.png',
            },
            {
                name: 'spiderTexture',
                path: '/static/textures/spider_provisional.jpg',
            },
            {
                name: 'sphereTexture',
                path: '/static/textures/protagonist.png',
            },
        ],
    },
    {
        type: 'audio',
        data: [
            {
                name: 'positiveBlip',
                path: '/static/audio/positive_blip.mp3',
            },
        ],
    },
    {
        type: 'models',
        data: [
            {
                name: 'multifactor',
                path: '/static/models/multifactor.gltf',
            },
            {
                name: 'antenna',
                path: '/static/models/antenna.gltf',
            },
            {
                name: 'spider',
                path: '/static/models/Spider_rigged_3.glb',
            },
        ],
    },
    {
        type: 'factorsAntennas',
        data: [
            {
                id: 1,
                content: 'Password',
                type: 'knowledge',
            },
            {
                id: 2,
                content: 'Security question',
                type: 'knowledge',
            },
            {
                id: 3,
                content: 'PIN',
                type: 'knowledge',
            },
            {
                id: 4,
                content: 'Smart card',
                type: 'possession',
            },
            {
                id: 5,
                content: 'Smartphone',
                type: 'possession',
            },
            {
                id: 6,
                content: 'Hardware token',
                type: 'possession',
            },
            {
                id: 7,
                content: 'Facial Recognition',
                type: 'inherence',
            },
            {
                id: 8,
                content: 'Fingerprint',
                type: 'inherence',
            },
            {
                id: 9,
                content: 'Retina scan',
                type: 'inherence',
            },
        ],
    },
];
