export default [
    {
        type: 'textures',
        data: [
            {
                name: 'sphereTexture',
                path: '/static/textures/protagonist.png',
            },
            {
                name: 'floorTexture',
                path: '/static/textures/floor_texture.jpg',
            },
            {
                name: 'glassTexture',
                path: '/static/textures/glass_texture.png',
            },
            {
                name: 'spiderTexture',
                path: '/static/textures/spider_provisional.jpg',
            },
        ],
    },
    {
        type: 'audio',
        data: [
            {
                name: 'positiveBlip',
                path: '/static/audio/positive_blip.mp3',
            },
        ],
    },
    {
        type: 'models',
        data: [
            {
                name: 'crypto',
                path: '/static/models/crypto.gltf',
            },
            {
                name: 'spider',
                path: '/static/models/Spider_rigged_3.glb',
            },
            {
                name: 'terminal',
                path: '/static/models/terminal.gltf',
            },
        ],
    },
    {
        type: 'passwordFeedback',
        data: [
            {
                id: 1,
                content: '✔️ the password is 12 characters or more',
                type: 'length',
            },
            {
                id: 2,
                content: '✔️ the password contains lowercase letters',
                type: 'lowercase',
            },
            {
                id: 3,
                content: '✔️ the password contains uppercase letter',
                type: 'uppercase',
            },
            {
                id: 4,
                content: '✔️ the password contains numbers',
                type: 'numbers',
            },
            {
                id: 5,
                content: '✔️ the password contains non-alphanumeric characters',
                type: 'nonalpha',
            },
            {
                id: 6,
                content: '✔️ the password is not one of the top ten million most common',
                type: 'rare',
            },
        ],
    },
];
