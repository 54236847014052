import * as THREE from 'three';
import Game from './game.js';
import Camera from './components/camera.js';
import Renderer from './components/renderer.js';
import Scoring from './components/scoring.js';
import World from './world/world.js';
import Resources from './utils/resources.js';
import Destroyable from './utils/destroyable.js';
//import TextRetriever from './utils/textRetriever';
//import { checkPassword } from './utils/passwordChecker.js';
import { mapConditionsOnFeedback } from './utils/passwordConsolePanel.js';

import sourcesMainframe from './data/sourcesMainframe.js';
import sourcesBehaviour from './data/sourcesBehaviour.js';
import sourcesMultifactor from './data/sourcesMultifactor.js';
import sourcesCrypto from './data/sourcesCrypto.js';
import { logLevelAnalytic, logGenericAnalytic } from './analytics.js';

//import textSources from './data/textSources.js';

let levelInstance = null;

export default class Level extends Destroyable {
    constructor(levelName) {
        if (levelInstance) {
            return levelInstance;
        }
        super(levelName);
        levelInstance = this;

        this.name = levelName;

        this.game = new Game();
        this.scoring = new Scoring();
        this.canvas = this.game.canvas;
        this.sizes = this.game.sizes;

        this.time = this.game.time;

        this.debug = this.game.debug;

        this.mainPanel = document.getElementById('main-overlay');

        this.scene = new THREE.Scene();
        if (levelName === 'mainframe') {
            this.resources = new Resources(sourcesMainframe, levelName);
        } else if (levelName === 'behaviour') {
            this.resources = new Resources(sourcesBehaviour, levelName);
        } else if (levelName === 'multifactor') {
            this.resources = new Resources(sourcesMultifactor, levelName);
        } else if (levelName === 'crypto') {
            this.resources = new Resources(sourcesCrypto, levelName);
            // since this is UI on game.html rather than three.js game we process these via utils
            mapConditionsOnFeedback(this.resources.textSources);
        }
        //this.textSources = new TextRetriever(textSources);

        this.audioReady = false;

        //this.resources = new Resources(sources, this.audioListener);

        this.camera = new Camera();
        this.renderer = new Renderer();
        this.world = new World(levelName);
        this.currentTextElement = null;

        if (levelName === 'mainframe') {
            /* S U C C E D E D block*/
            if (this.scoring.globalScore['behaviour'] && this.scoring.globalScore['multifactor'] && this.scoring.globalScore['crypto']) {
                //console.log('all levels completed');
                this.scoring.report('game-over-finished');
                // pause timer so that we don't send millions of requests to the backend
                this.scoring.timerPause();
                this.game.showPanel('mainframe', 'game-over-finished');

                //disable the spider!
                /*TODO: consider waiting for everything to be loaded rather than hardcoding delay*/
                setTimeout(() => {
                    this.world.protagonist.controller.isActive = false;
                    this.world.spider.disableSpiderShadow();
                }, 1500);
            }
        } else if (levelName === 'behaviour') {
            this.intersectedObelisk = (obeliskObject) => {
                if (this.resources.textSources[obeliskObject.userData.textId] === undefined) {
                    this.createFlashlightTextElement(this.resources.textSources[1].content);
                } else {
                    this.createFlashlightTextElement(this.resources.textSources[obeliskObject.userData.textId].content);
                }
                this.scoring.addIntersectedObelisks(obeliskObject);
            };
            this.world.on('intersected_obelisk', this.intersectedObelisk);

            /*could have a callback for the obelisk if we want to change its state obeliskObject*/
            this.stoppedIntersectingObelisk = () => {
                this.removeTextElement(this.currentTextElement);
            };
            this.world.on('stopped_intersecting_obelisk', this.stoppedIntersectingObelisk);

            this.intersectedAll = () => {
                //open the exit - ready to leave
                this.openExit('behaviour');
            };

            this.scoring.on('intersected_all', this.intersectedAll);

            /* Debug zone -
             * uncomment to have early exit option for testing
             * the conditions across the levels (behaviourZone works)
             */
            /*setTimeout(() => {
                this.openExit('behaviour');

            }, 2000);*/
        } else if (levelName === 'multifactor') {
            this.intersectedAntenna = (antennaObject) => {
                if (this.resources.textSources[antennaObject.parent.userData.textId] === undefined) {
                    this.createFlashlightTextElement(this.resources.textSources[1].content);
                } else {
                    this.createFlashlightTextElement(this.resources.textSources[antennaObject.parent.userData.textId].content);
                }
            };
            this.world.on('intersected_antenna', this.intersectedAntenna);

            this.stoppedIntersectingAntenna = () => {
                this.removeTextElement(this.currentTextElement);
            };
            this.world.on('stopped_intersecting_antenna', this.stoppedIntersectingAntenna);
        } else if (levelName === 'crypto') {
            /*
             * NOTE on crypto logic
             * Password console event panel opening - touchGate method on cryptoGate is the original trigger
             * but going through scoring to maintain a degree of consistency with firing events
             *
             * logic for password testing handled in passwordConsolePanel.js and passwordChecker.js
             * password evaluation triggered from game.html
             */
            this.scoring.on('password_challenge_active', () => {
                //disable controller moving while player is solving the password puzzle
                this.world.protagonist.controller.isActive = false;

                // uncover the console for entering the password
                document.getElementById('crypto-data-wrapper').classList.remove('hidden');

                /* this will remain hidden by alpine until the succeeded=true is not returned from utils */
                document.getElementById('crypto-done').addEventListener('click', () => {
                    document.getElementById('crypto-data-wrapper').classList.add('hidden');
                    // remove the gate
                    this.world.cryptoGate.removeGate();
                    this.world.protagonist.controller.isActive = true;
                    this.openExit('crypto');
                });
            });
        }

        // closure needed for scoping?
        this.tick = () => {
            this.update();
        };
        this.time.on('tick', this.tick);

        this.exitingLevel = (goingToLevel) => {
            //TODO: bespoke message with feedback on the result.
            this.scoring.timerPause();

            //TODO: Add logic based on which level you are going to

            if (this.name !== 'mainframe') {
                //this.createEndTextElement(this.scoring.getResult(), goingToLevel);
                this.endLevelFeedback(this.scoring.getResult(), goingToLevel);

                this.scoring.report(this.name, this.scoring.getResult());
            } else {
                // it's the mainframe level logic
                setTimeout(() => {
                    //this.scoring.timerPause();
                    this.game.startNewLevel(goingToLevel);
                }, 250);
            }
        };
        this.world.on('exiting_level', this.exitingLevel);

        this.documentClick = () => {
            if (!this.audioReady) {
                this.audioReady = true;
                this.audioListener = new THREE.AudioListener();
                this.scene.add(this.audioListener);
                this.resources.loadAudio(this.audioListener);
            }
        };
        document.addEventListener('click', this.documentClick);
    }

    update() {
        this.camera.update();
        this.renderer.update();
    }

    /*method for non-mainframe level*/
    confirmedLevelExit() {
        setTimeout(() => {
            this.game.startNewLevel();
        }, 250);
    }

    createFlashlightTextElement(text) {
        if (this.currentTextElement != null) {
            // make certain no textelement gets abandoned without being removed
            this.removeTextElement(this.currentTextElement);
        }

        const game = document.getElementById('game');

        this.flashlightWrapper = document.createElement('div');
        this.flashlightWrapper.className = `obelisk-wrapper-${Date.now()} pointer-events-none absolute z-20 grid grid-cols-2 grid-rows-2 place-items-center w-full h-full`;

        this.flashlightDiv = document.createElement('div');
        this.flashlightDiv.className =
            'inline-block col-start-2 h-auto max-h-min max-w-[30rem] m-2 md:m-20 md:justify-self-start py-4 md:py-8 text-md md:text-lg font-medium leading-6 text-gray-50 text-center backdrop-blur-xl rounded-lg border border-gray-50';
        this.flashlightWrapper.appendChild(this.flashlightDiv);

        this.textSpan = document.createElement('p');
        this.textSpan.className = 'px-6';
        this.textSpan.innerHTML = text;
        this.flashlightDiv.append(this.textSpan);

        game.insertBefore(this.flashlightWrapper, game.firstChild);
        this.currentTextElement = this.flashlightWrapper;
    }

    passPasswordFeedback() {}

    endLevelFeedback(succeeded, nextLevel) {
        if (this.name == 'behaviour') {
            this.game.showPanel('behaviour', succeeded ? 'feedback-positive' : 'feedback-negative');
            var nextlevelgo = ((e) => {
                document.getElementById('resume-button').removeEventListener('click', nextlevelgo);
                setTimeout(this.scoring.timerUnpause.bind(this), 350);
                this.confirmedLevelExit();
            }).bind(this);
            document.getElementById('resume-button').addEventListener('click', nextlevelgo);
        } else if (this.name == 'multifactor') {
            this.confirmedLevelExit();
        } else if (this.name == 'crypto') {
            this.confirmedLevelExit();
        } else {
            console.log(`end level not implemented for this level: ${this.name}`);
        }
    }

    removeTextElement(elem) {
        setTimeout(() => {
            if (elem != null) {
                elem.remove();
            }
        }, 250);
    }

    // used in behaviour and crypto levels
    openExit(levelName) {
        // minimal delay on exit
        setTimeout(() => {
            // Change door colour to ease the intensity
            this.scene.traverse((child) => {
                let newMat = new THREE.MeshBasicMaterial({
                    color: 0x00ff00,
                });
                if (child.name.includes('Door')) {
                    child.material = newMat;
                }
            });

            /* Make the exit possible to collide with */
            this.world.environment.generateExit();

            this.game.showPanel(levelName, 'feedback-exit');
        }, 500);
    }

    destroy() {
        levelInstance = null;

        this.scoring.specificCallbackOff('intersected_all', this.intersectedAll);
        delete this.intersectedAll;
        this.world.specificCallbackOff('intersected_obelisk', this.intersectedObelisk);
        delete this.intersectedObelisk;
        this.world.specificCallbackOff('stopped_intersecting_obelisk', this.stoppedIntersectingObelisk);
        delete this.stoppedIntersectingObelisk;
        this.world.specificCallbackOff('exiting_level', this.exitingLevel);
        delete this.exitingLevel;
        this.time.specificCallbackOff('tick', this.tick);
        delete this.tick;
        document.removeEventListener('click', this.documentClick);
        delete this.documentClick;

        // in addition to freeing Destroyables, this needs to dispose() of 3js objects

        let sceneElementsToDestroy = [];

        this.scene.traverse((child) => {
            if (child instanceof THREE.Mesh || child instanceof THREE.LineSegments) {
                child.geometry.dispose();
                child.material.dispose();
            } else if (child instanceof THREE.Group) {
                /*child of the group might be a mesh or a nested group with more meshes*/
                child.children.forEach((groupChild) => {
                    if (groupChild instanceof THREE.Mesh) {
                        groupChild.geometry.dispose();
                        groupChild.material.dispose();
                    } else if (groupChild instanceof THREE.Group) {
                        groupChild.children.forEach((groupNestedChild) => {
                            if (groupNestedChild instanceof THREE.Mesh) {
                                groupNestedChild.geometry.dispose();
                                groupNestedChild.material.dispose();
                            }
                        });
                    }
                });
            }
            sceneElementsToDestroy.push(child);
        });

        sceneElementsToDestroy.forEach((child) => {
            this.scene.remove(child);
        });

        this.canvas.removeChild(this.renderer.instance.domElement);
        this.renderer.instance.dispose();
        if (this.debug.active) this.debug.ui.destroy();
    }
}
