import * as THREE from 'three';
import Level from '../level.js';
import Controller from '../components/controller';
import * as CANNON from 'cannon-es';
import Destroyable from '../utils/destroyable.js';

export default class Protagonist extends Destroyable {
    constructor(levelName) {
        super(levelName);
        this.level = new Level();
        this.scene = this.level.scene;
        this.world = this.level.world.world;
        this.resources = this.level.resources;
        this.controller = new Controller(levelName);

        // Setup
        this.setGeometry();
        this.setTextures();
        this.setMaterial();
        this.setMesh();
        this.setBody();

        this.debug = this.level.debug;
        if (this.debug.active) {
            this.debugFolder = this.debug.ui.addFolder('protagonist');
        }
    }

    setGeometry() {
        this.geometry = new THREE.SphereGeometry(1, 32, 16);
    }

    setTextures() {
        this.textures = {};
        this.textures.color = this.resources.items.sphereTexture;
    }

    setMaterial() {
        this.material = new THREE.MeshLambertMaterial({
            map: this.textures.color,
        });
        // this.material = new THREE.MeshStandardMaterial({
        //     color: 0xb91c1c,
        //     roughness: 0.65,
        //     metalness: 0.65,
        //     flatShading: false,
        // });
    }

    setMesh() {
        this.mesh = new THREE.Mesh(this.geometry, this.material);

        this.mesh.position.set(0, 4, 0);

        this.scene.add(this.mesh);
    }

    setBody() {
        /* protagonist's physics body */
        /*    this.sphereShape = new CANNON.Sphere(1);
            this.sphereBody = new CANNON.Body({
              material: this.world.plasticMaterial,
              mass: 10,
              position: new CANNON.Vec3(0, 5, 0),
              shape: this.sphereShape,
            });
            this.sphereBody.linearDamping = 0.1;
            this.sphereBody.angularDamping = 0.5;
            this.world.addBody(this.sphereBody);*/

        const radius = 1; // m
        let sphereShape = new CANNON.Sphere(radius);
        this.sphereBody = new CANNON.Body({
            /*
            mass: getMassFromShape(sphereShape),
            */
            mass: 2,
            shape: sphereShape,
            /* material: this.world.bouncyMaterial,*/
        });
        this.sphereBody.position.set(0, 5, 0); // m
        this.mesh.castShadow = true;
        this.mesh.receiveShadow = true;
        this.world.addBody(this.sphereBody);
    }

    destroy() {
        this.world.removeBody(this.sphereBody);
    }
}
