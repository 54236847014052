/*
 * https://github.com/pmndrs/cannon-es/issues/103#issuecomment-1002183975
 * and
 * https://github.com/pmndrs/cannon-es/issues/103#issuecomment-1003108540\
 * */
import * as THREE from 'three';
import { mergeVertices } from 'three/addons/utils/BufferGeometryUtils.js';
import * as CANNON from 'cannon-es';
import { QuickHull } from './quickHull.js';

function getPolyhedronShape(mesh) {
    let geometry = new THREE.BufferGeometry();
    geometry.setAttribute('position', mesh.geometry.getAttribute('position'));

    geometry = mergeVertices(geometry);

    const position = geometry.attributes.position.array;

    const points = [];
    for (let i = 0; i < position.length; i += 3) {
        points.push(new CANNON.Vec3(position[i], position[i + 1], position[i + 2]));
    }

    const faces = QuickHull.createHull(points);
    return new CANNON.ConvexPolyhedron({ vertices: points, faces });
}

export { getPolyhedronShape };
