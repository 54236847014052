import { checkPassword } from './passwordChecker.js';

let passwordConditionsFeedback = [];

function mapConditionsOnFeedback(cryptoTextSources) {
    passwordConditionsFeedback = cryptoTextSources;
}
function evaluateEnteredPassword(passwordInput) {
    checkPassword(passwordInput.value)
        .then((result) => {
            //console.log(result.succeeded);
            let feedbackList = [];

            for (const conditionMet of Object.entries(result.conditionsMet)) {
                //console.log(conditionMet);

                for (let index in passwordConditionsFeedback) {
                    let item = passwordConditionsFeedback[index];
                    //mapping through 'type' of sourcesCrypto to passwordChecker
                    for (let dataType in item) {
                        if (dataType == 'type') {
                            if (item[dataType] == conditionMet[1]) {
                                //console.log(item['content']);
                                feedbackList.push(item['content']);
                            }
                        }
                    }
                }
            }
            document.getElementById('crypto-console').dispatchEvent(
                new CustomEvent('passwordfeedback', {
                    detail: {
                        succeeded: result.succeeded,
                        feedbackList: feedbackList,
                    },
                })
            );
        })
        .catch((error) => {
            console.error('Got an error: ', error);
        });

    //console.log(passwordInput.value);
}

export { evaluateEnteredPassword, mapConditionsOnFeedback };
