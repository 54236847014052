import * as THREE from 'three';
import Level from '../level.js';
import Scoring from '../components/scoring.js';
import * as CANNON from 'cannon-es';
import { generatePositions } from '../utils/positionGenerator.js';
import Destroyable from '../utils/destroyable';
//import { getPolyhedronShape } from '../utils/meshToConvex';

export default class Antenna extends Destroyable {
    constructor(levelName) {
        super(levelName);
        this.level = new Level();
        this.scoring = new Scoring();
        this.scene = this.level.scene;
        this.world = this.level.world.world;
        this.resources = this.level.resources;
        this.protagonist = this.level.world.protagonist;

        this.time = this.level.time;

        //this.targetPosition = targetPosition
        this.antennaPositions = generatePositions(9, 0);
        this.antennaMeshes = [];
        this.antennaBodies = [];
        this.markedPair = [];
        this.markedTriple = [];
        this.mode = 'pair';
        this.indicatorCylinders = [];
        this.lastActivatedAntenna = null;

        this.yellowMaterial = new THREE.MeshBasicMaterial({
            color: 0xfacc15,
        });
        this.redMaterial = new THREE.MeshBasicMaterial({
            color: 0xfa1010,
        });
        this.greenMaterial = new THREE.MeshBasicMaterial({
            color: 0x20fa20,
        });

        this.setModel();

        this.tick = () => {
            this.update();
        };
        this.time.on('tick', this.tick);
    }

    setAudio() {
        this.hitSound = this.resources.items.positiveBlip;
        //      this.hitSound.play();
    }

    setModel() {
        this.antennaModel = this.resources.items.antenna;
        let antennaMesh = this.antennaModel.scene;

        for (let i = 0; i <= this.antennaPositions.length; i++) {
            if (this.antennaPositions[i] instanceof THREE.Vector3) {
                let antennaMeshClone = antennaMesh.clone();
                antennaMeshClone.position.x = this.antennaPositions[i].getComponent(0);
                antennaMeshClone.position.y = this.antennaPositions[i].getComponent(1);
                antennaMeshClone.position.z = this.antennaPositions[i].getComponent(2);

                let id = i + 1;
                antennaMeshClone.userData.textId = id;
                for (const [key, value] of Object.entries(this.resources.items)) {
                    if (id === Number(key)) {
                        antennaMeshClone.traverse((child) => {
                            if (child.name.includes('Factor')) {
                                child.material = this.createMaterial(value);
                            }
                        });
                    }
                }

                this.scene.add(antennaMeshClone);

                this.antennaMeshes.push(antennaMeshClone);
                this.setBody(antennaMeshClone, this.antennaPositions[i]);
            }
        }
    }

    checkConnectionPair(idA, idB) {
        if (this.resources.textSources[idA]['type'] == this.resources.textSources[idB]['type']) {
            // same type (this elegantly covers the case where idA == idB as well)
            return false;
        } else if (this.markedPair.includes(idA) || this.markedPair.includes(idB)) {
            // using pair check for the first pair of a triple
            return false;
        } else {
            // that's the only condition
            return true;
        }
    }

    checkConnectionTriple(idA, idB, idC) {
        if (
            this.resources.textSources[idA]['type'] == this.resources.textSources[idB]['type'] ||
            this.resources.textSources[idA]['type'] == this.resources.textSources[idC]['type'] ||
            this.resources.textSources[idB]['type'] == this.resources.textSources[idC]['type']
        ) {
            // a pair of the same type
            return false;
        } else if (this.markedPair.includes(idA) || this.markedPair.includes(idB) || this.markedPair.includes(idC)) {
            // one is already used
            return false;
        } else {
            // valid triple
            return true;
        }
    }

    makeCylinder(pointX, pointY) {
        // edge from X to Y
        const direction = new THREE.Vector3().subVectors(pointY, pointX);
        // Make the geometry (of "direction" length)
        const geometry = new THREE.CylinderGeometry(0.05, 0.05, 1, 6, 1, true);
        // shift it so one end rests on the origin
        geometry.applyMatrix4(new THREE.Matrix4().makeTranslation(0, 1 / 2, 0));
        // rotate it the right way for lookAt to work
        geometry.applyMatrix4(new THREE.Matrix4().makeRotationX(THREE.MathUtils.degToRad(90)));
        // Make a mesh with the geometry
        const cylinder = new THREE.Mesh(geometry, this.yellowMaterial);
        // Position it where we want
        cylinder.position.copy(pointX);
        // And make it point to where we want
        cylinder.lookAt(pointY);
        // stretch it to the right length
        cylinder.scale.set(1, 1, direction.length());

        // return cylinder;
        this.scene.add(cylinder);
        return cylinder;
    }

    positionCylinder(cylinder, pointA, pointB) {
        cylinder.position.copy(pointA);
        cylinder.lookAt(pointB);
        cylinder.scale.set(1, 1, new THREE.Vector3().subVectors(pointB, pointA).length());
    }

    createMaterial(texture) {
        let factorMaterial = new THREE.MeshStandardMaterial({
            map: texture,
        });

        texture.wrapS = THREE.RepeatWrapping; // horizontal wrapping
        texture.wrapT = THREE.ClampToEdgeWrapping; // vertical wrapping

        texture.repeat.set(4, 4); // horizontal, vertical
        texture.offset.set(0, 2.5); // x, y
        // texture.center.set(0, 0);
        texture.rotation = THREE.MathUtils.degToRad(90);
        // texture.minFilter = THREE.LinearFilter;

        // If texture is used for color information, set colorspace.
        texture.encoding = THREE.sRGBEncoding;
        // // UVs use the convention that (0, 0) corresponds to the upper left corner of a texture.
        texture.flipY = false;
        return factorMaterial;
    }

    setBody(meshBase, meshPosition) {
        //let collisionConvex = getPolyhedronShape(meshBase);
        // const axisSize = 1.25;
        const vectorSize = new CANNON.Vec3(1.25, 2.1, 1.25);
        let antennaBody = new CANNON.Body({
            mass: 100,
            shape: new CANNON.Box(vectorSize),
        });

        //obeliskBody.addShape(collisionConvex);
        antennaBody.position.set(meshPosition.getComponent(0), meshPosition.getComponent(1) + 2.3, meshPosition.getComponent(2));
        this.world.addBody(antennaBody);
        this.antennaBodies.push(antennaBody);

        antennaBody.active = true;
        let antennaCollisionListener = (e) => {
            if (e.body == this.level.world.protagonist.sphereBody && this.mode != 'done') {
                if (antennaBody.active) {
                    antennaBody.active = false;

                    // only re-enable if the object is not already in the antenna pair
                    setTimeout(() => {
                        if (!this.markedPair.includes(meshBase.userData.textId)) {
                            antennaBody.active = true;
                        }
                    }, 1000);

                    if (this.indicatorCylinders.length > 0) {
                        this.indicatorCylinders.forEach((cylinder) => {
                            this.positionCylinder(cylinder, cylinder.position, new THREE.Vector3(meshBase.position.x, 4, meshBase.position.z));
                        });
                        var tryAdd = false;
                        if (this.mode == 'pair') {
                            tryAdd = this.checkConnectionPair(meshBase.userData.textId, this.selectedAntennaId[0]);
                        } else if (this.mode == 'triple') {
                            if (this.selectedAntennaId.length == 1) {
                                tryAdd = this.checkConnectionPair(meshBase.userData.textId, this.selectedAntennaId[0]);
                            } else {
                                tryAdd = this.checkConnectionTriple(meshBase.userData.textId, this.selectedAntennaId[0], this.selectedAntennaId[1]);
                            }
                        }
                        if (tryAdd) {
                            this.selectedAntennaId.push(meshBase.userData.textId);
                            if (this.mode == 'pair') {
                                this.indicatorCylinders.forEach((cylinder) => {
                                    cylinder.material = this.greenMaterial;
                                });
                                this.markedPair = [...this.selectedAntennaId];

                                this.indicatorCylinders = [];
                                this.mode = 'triple';

                                this.level.game.showPanel('multifactor', 'feedback-pair');
                            } else if (this.mode == 'triple') {
                                if (this.selectedAntennaId.length == 3) {
                                    this.indicatorCylinders.forEach((cylinder) => {
                                        cylinder.material = this.greenMaterial;
                                    });
                                    this.thirdTriangleCylinder.material = this.greenMaterial;
                                    this.markedTriple = [...this.selectedAntennaId];
                                    this.indicatorCylinders = [];

                                    this.mode = 'done';

                                    this.level.openExit('multifactor');
                                    // Change door colour
                                    /*                      this.scene.traverse((child) => {
                                        let newMat = new THREE.MeshBasicMaterial({
                                            color: 0x00ff00,
                                        });
                                        if (child.name.includes('Door')) {
                                            child.material = newMat;
                                        }
                                    });
                                    this.level.world.environment.generateExit();
                                    this.level.game.showPanel('multifactor', 'feedback-exit');
                                    */
                                } else if (this.selectedAntennaId.length == 2) {
                                    this.indicatorCylinders.push(this.makeCylinder(new THREE.Vector3(meshBase.position.x, 4, meshBase.position.z), this.protagonist.mesh.position));
                                    // connect the two already selected antenna
                                    this.thirdTriangleCylinder = this.makeCylinder(this.indicatorCylinders[0].position, this.indicatorCylinders[1].position);
                                }
                            }
                        } else {
                            this.indicatorCylinders.forEach((cylinder) => {
                                cylinder.material = this.redMaterial;
                            });
                            if (this.thirdTriangleCylinder) {
                                this.thirdTriangleCylinder.material = this.redMaterial;
                            }
                            setTimeout(
                                ((indicatorCylinders) => {
                                    indicatorCylinders.forEach((cylinder) => {
                                        this.scene.remove(cylinder);
                                    });
                                    if (this.thirdTriangleCylinder) {
                                        this.scene.remove(this.thirdTriangleCylinder);
                                    }
                                }).bind(this, this.indicatorCylinders),
                                300
                            );
                            this.indicatorCylinders = [];
                            if (!this.selectedAntennaId.includes(meshBase.userData.textId)) {
                                this.level.game.showPanel('multifactor', 'feedback-mistake');
                            }
                        }
                    } else if (this.mode != 'done') {
                        this.indicatorCylinders = [this.makeCylinder(new THREE.Vector3(meshBase.position.x, 4, meshBase.position.z), this.protagonist.mesh.position)];
                        this.selectedAntennaId = [meshBase.userData.textId];
                    }
                }
            }
        };

        /*TODO: remove listeners?*/
        antennaBody.addEventListener('collide', antennaCollisionListener);
    }

    update() {
        if (this.indicatorCylinders.length > 0) {
            this.indicatorCylinders.forEach((cylinder) => {
                this.positionCylinder(cylinder, cylinder.position, this.protagonist.mesh.position);
            });
        }
        /*for (let i = 0; i <= this.antennaMeshes.length; i++) {
            if (this.antennaBodies[i]) {
                // dont need to change the antenna body
                this.antennaMeshes[i].position.copy(this.antennaBodies[i].position);
                this.antennaMeshes[i].quaternion.copy(this.antennaBodies[i].quaternion);
            }
        }*/
    }

    destroy() {
        this.time.specificCallbackOff('tick', this.tick);
    }
}
