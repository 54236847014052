import * as CANNON from 'cannon-es';

import Level from '../level.js';

import { getPolyhedronShape } from '../utils/meshToConvex.js';
import * as THREE from 'three';
import { Vector3 } from 'three';
import Scoring from '../components/scoring.js';

export default class Floor {
    constructor(levelName) {
        this.level = new Level();
        this.scene = this.level.scene;
        this.resources = this.level.resources;
        this.world = this.level.world.world;
        this.levelName = levelName;

        this.scoring = new Scoring();
        this.triggerDict = {};

        /*Non-mainframe scene to save the location for the exit portal*/
        this.savedExitData = false;

        /*to generate triggers as appropriate in a consistent fashion*/
        this.mainframeWallsReady = {
            behaviour: false,
            multifactor: false,
            crypto: false,
            compliance: false, // just to patch that last part of the wall
        };

        // Setup
        this.setModel();
    }

    setModel() {
        if (this.levelName === 'mainframe') {
            this.sceneEnvironment = this.resources.items.mainframe;
        } else if (this.levelName === 'behaviour') {
            this.sceneEnvironment = this.resources.items.behaviour;
        } else if (this.levelName === 'multifactor') {
            this.sceneEnvironment = this.resources.items.multifactor;
        } else if (this.levelName === 'crypto') {
            this.sceneEnvironment = this.resources.items.crypto;
        }

        this.scene.add(this.sceneEnvironment.scene);
        this.sceneEnvironment.scene.receiveShadow = true;

        this.scene.traverse((child) => {
            child.receiveShadow = true;
            /*Change door colours*/
            /*
            let newMat = new THREE.MeshBasicMaterial({
                color: 0x00ff00,
            });
            if (child.name.includes('Door')) {
                child.material = newMat;
            }
            */

            if (this.levelName === 'mainframe') {
                let newMat = new THREE.MeshBasicMaterial({
                    color: 0x00ff00,
                });
                /*"Door_ComplianceZone" is excluded*/
                if (child.name.includes('Door_BehaviourZone')) {
                    if (!this.scoring.globalScore['behaviour']) child.material = newMat;
                } else if (child.name.includes('Door_CryptoZone')) {
                    if (!this.scoring.globalScore['crypto']) child.material = newMat;
                } else if (child.name.includes('Door_MultifactorZone')) {
                    if (!this.scoring.globalScore['multifactor']) child.material = newMat;
                }
            }

            if (child.name.includes('Mainframe_Faces')) {
                this.calculateCollisionArea(child);
            }
        });

        // TESTING DOOR COLOUR, KEEP PLEASE
        // this.scene.traverse((child) => {
        //     let newMat = new THREE.MeshBasicMaterial({
        //         color: 0x00ff00,
        //     });
        //     if (child.name.includes('Door')) {
        //         child.material = newMat
        //         console.log('Invisible door is visible')
        //     }
        //
        // });
    }

    /*
     * https://threejs.org/manual/#en/custom-buffergeometry
     * - Need to compute bounding sphere of the mainframe faces to then get the radius and create a flat circle,
     * put it on the floor and calculate the segments to serve as wall locations
     *
     * - Number of segments, which is the same as number of faces of mainframe, can be used to calculate the width of collision geometries:
     * First need to get circumference from the radius, then divide by number of segments, helps to floor() to reduce overlap
     *
     * - For Loop:
     * Current length of circleGeometry.attributes.position.array.length, given 16 segments is 54 (16*3 + centre + a point?)
     * we are skipping first 3 entries in array buffer as this is the centre of the circle.
     * Our target space geometry has 12 'inactive faces' (collider walls) and 4' active' ones (triggers).
     * Due to 'extra' point in the buffer module needs to have an offset to instantiate the right body where we should have a trigger instead of collision
     * */

    calculateCollisionArea(meshToSphere) {
        meshToSphere.geometry.computeBoundingSphere();
        let boundingRadius = meshToSphere.geometry.boundingSphere.radius;

        const circleGeometry = new THREE.CircleGeometry(boundingRadius, 16);
        circleGeometry.rotateX(Math.PI / 2);

        /*adjusting rotation for matching colours with collidable meshes*/
        if (this.levelName === 'mainframe') {
            circleGeometry.rotateY(Math.PI / 2);
        } else {
            /*Rotating to match currently coloured green door in behaviour zone model*/
            circleGeometry.rotateY(Math.PI / 2);
        }

        const circleCircumference = 2 * 3.14 * boundingRadius;

        const moduleWidth = Math.floor(circleCircumference / circleGeometry.parameters.segments);

        for (let i = 3; i < circleGeometry.attributes.position.array.length; i += 3) {
            let newPosition = new Vector3(circleGeometry.attributes.position.array[i], circleGeometry.attributes.position.array[i + 1], circleGeometry.attributes.position.array[i + 2]);
            let offset = i + 1;
            if (offset % 4 === 0) {
                /*Just like model has an arbitrary direction of doors*/
                if (this.levelName === 'mainframe') {
                    /*Sadly, need a fixed order that will reflect the order in geometry*/

                    if (!this.mainframeWallsReady['behaviour']) {
                        this.drawWallModule(newPosition, moduleWidth, !this.scoring.globalScore['behaviour'], 'behaviour');
                        this.mainframeWallsReady['behaviour'] = true;
                    } else if (!this.mainframeWallsReady['compliance']) {
                        /*DUMMY for the unused trigger wall - the 4th game*/
                        this.drawWallModule(newPosition, moduleWidth, false, 'compliance');
                        this.mainframeWallsReady['compliance'] = true;
                    } else if (!this.mainframeWallsReady['multifactor']) {
                        this.drawWallModule(newPosition, moduleWidth, !this.scoring.globalScore['multifactor'], 'multifactor');
                        this.mainframeWallsReady['multifactor'] = true;
                    } else if (!this.mainframeWallsReady['crypto']) {
                        this.drawWallModule(newPosition, moduleWidth, !this.scoring.globalScore['crypto'], 'crypto');
                        this.mainframeWallsReady['crypto'] = true;
                    }
                } else {
                    /*In non-mainframe scene*/
                    /*save same exit for later, block temporarily*/
                    this.drawWallModule(newPosition, moduleWidth, false, 'wall');
                    if (!this.savedExitData) {
                        this.triggerDict['position'] = newPosition;
                        this.triggerDict['width'] = moduleWidth;
                        this.savedExitData = true;
                    }
                }
            } else {
                // non-door wall to stop you going outside the dome
                this.drawWallModule(newPosition, moduleWidth, false, '');
            }
        }
    }

    generateExit() {
        this.drawWallModule(this.triggerDict['position'], this.triggerDict['width'], true, 'mainframe');
    }

    /*
     * Generating wall module based on size calculations
     *
     * Some elements commented out but useful for visualising
     *
     * BoxGeometry's width is derived from circle geometry circumference width
     * Then we position boundaryBox on the circumference segment location,
     * and establish a backup convex
     * (unless object is a trigger in which case it should not be necessary if it wasn't for uncertain 1layer collision)
     */

    drawWallModule(position, moduleWidth, isTrigger, moduleName) {
        const boxGeometry = new THREE.BoxGeometry(moduleWidth, 30, 14);
        const material = new THREE.MeshBasicMaterial({ color: isTrigger ? 0x00ff00 : 0xffff00 });
        const boundaryBox = new THREE.Mesh(boxGeometry, material);

        // sort out pointing
        boundaryBox.position.set(position.x, position.y, position.z);
        boundaryBox.lookAt(0, 0, 0);

        //to prevent boundaryBox going through the floor
        boundaryBox.position.set(position.x, position.y + 15, position.z);

        //debugging view only
        //this.scene.add(boundaryBox);

        /*
         * Box's shape is made of halfExtents, vector half length on of its local axis
         * https://pmndrs.github.io/cannon-es/docs/classes/Box.html
         */

        const boxExtents = new CANNON.Vec3(boundaryBox.geometry.parameters.width / 2, boundaryBox.geometry.parameters.height / 2, boundaryBox.geometry.parameters.depth / 2);
        const boxShape = new CANNON.Box(boxExtents);

        let boundaryBoxBody = new CANNON.Body({
            mass: 0,
            shape: boxShape,
        });

        // debug
        // boundaryBoxBody.position.copy(boundaryBox.position)

        boundaryBoxBody.position.set(boundaryBox.position.x, boundaryBox.position.y, boundaryBox.position.z);
        boundaryBoxBody.quaternion.copy(boundaryBox.quaternion);
        this.world.addBody(boundaryBoxBody);

        this.setBody(boundaryBox, isTrigger, moduleName);
    }

    /*
     * Generate convex around the mesh,
     * align position and rotation
     */
    setBody(meshToConvex, isTrigger, moduleName) {
        // to make sure we acknowledge each trigger created and decreate the count so non-triggerable colliders get made.

        let collisionConvexShape = getPolyhedronShape(meshToConvex);
        const boundaryConvexBody = new CANNON.Body({
            mass: 0,
            isTrigger: isTrigger,
        });

        if (isTrigger) {
            boundaryConvexBody.userData = { level: moduleName };
        }

        boundaryConvexBody.addShape(collisionConvexShape);
        boundaryConvexBody.position.set(meshToConvex.position.x, meshToConvex.position.y, meshToConvex.position.z);
        boundaryConvexBody.quaternion.copy(meshToConvex.quaternion);
        this.world.addBody(boundaryConvexBody);
    }
}
