import * as THREE from 'three';
import Level from '../level.js';

export default class Lights {
    constructor() {
        this.level = new Level();
        this.scene = this.level.scene;

        // Setup
        // this.setAmbientLight();
        this.setHemisphereLight();
        this.setSunLight();
    }

    // setAmbientLight() {
    //     this.ambientLight = new THREE.AmbientLight('0xA3A3A3', 2);
    //     this.scene.add(this.ambientLight);
    // }

    setHemisphereLight() {
        this.hemispherelight = new THREE.HemisphereLight(0xffffff, 0x0000ff, '1');
        this.scene.add(this.hemispherelight);
    }

    setSunLight() {
        this.sunLight = new THREE.DirectionalLight(0xffffff, 1);
        this.sunLight.castShadow = true;
        this.sunLight.shadow.camera.far = 40;
        this.sunLight.shadow.camera.top = 50;
        this.sunLight.shadow.camera.bottom = -50;
        this.sunLight.shadow.camera.left = -50;
        this.sunLight.shadow.camera.right = 50;
        this.sunLight.shadow.mapSize.set(3000, 3000);
        this.sunLight.shadow.normalBias = 0.05;
        this.sunLight.position.set(-6, 25, -5);
        this.scene.add(this.sunLight);
    }
}
