import * as THREE from 'three';

import EventEmitter from './eventEmitter.js';

export default class Time extends EventEmitter {
    constructor() {
        super();

        this.clock = new THREE.Clock();
        // Setup
        this.start = Date.now();
        this.current = this.start;
        this.elapsed = 0;
        this.delta = 16;

        /*
         * We could have called the tick method immediately in the constructor without the window.requestAnimationFrame, but this would result in a delta equal to 0 on the first frame.
         * */
        window.requestAnimationFrame(() => {
            this.tick();
        });
    }

    tick() {
        const currentTime = Date.now();
        this.delta = currentTime - this.current;
        this.current = currentTime;
        this.elapsed = this.current - this.start;

        this.trigger('tick');

        window.requestAnimationFrame(() => {
            this.tick();
        });
    }
}
