import * as THREE from 'three';
import * as CANNON from 'cannon-es';

import DestroyableEventEmitter from '../utils/destroyable';
import Level from '../level';

// Same camera angle at start, useful for testing particular levels
// let cameraRotation = 0;
// Randomise camera angle at start, so people try different levels first each time
let cameraRotation = Math.floor(Math.random() * 4) * (Math.PI / 2);
export default class Controller extends DestroyableEventEmitter {
    constructor(levelName) {
        super(levelName);
        console.log(`im in ${levelName}`);
        //this.levelName = levelName

        this.level = new Level();
        this.time = this.level.time;
        this.camera = this.level.camera;
        this.world = this.level.world;

        this.isActive = true;

        this.worldReady = () => {
            this.setInstance();
        };
        this.world.on('world_ready', this.worldReady);

        this.last_frame = Date.now();
        this.ticker = () => {
            this.update();
        };
        this.time.on('tick', this.ticker);
    }

    /*
        setControllerActive(isActive){
            this.isActive = isActive;
        }
    */

    clickCircle(evt) {
        this.offset = this.getMousePosition(evt);
        this.isMouseDown = true;
        this.protagonist.sphereBody.angularDamping = 0.01;
    }

    moveCircle(evt) {
        if (!this.isMouseDown) return;
        this.mouse = this.getMousePosition(evt);
        let leftPos = this.mouse.x - this.offset.x;
        let topPos = this.mouse.y - this.offset.y;

        // if just getting radius by halving, the element ends up on the border
        this.maxRadius = (this.controllerHolder.getBoundingClientRect().width - this.controller.getBoundingClientRect().width) / 2;
        this.maxRadiusSquared = this.maxRadius * this.maxRadius;

        const sqMag = leftPos * leftPos + topPos * topPos;
        const magnitude = Math.sqrt(sqMag);
        if (magnitude > this.maxRadius) {
            leftPos /= magnitude;
            topPos /= magnitude;
            leftPos *= this.maxRadius;
            topPos *= this.maxRadius;
        }

        // set the element's new position:
        this.controller.style.left = `${leftPos}px`;
        this.controller.style.top = `${topPos}px`;

        this.roll.forwards = magnitude < 0.1 ? 0 : -topPos / this.maxRadius;
        this.roll.rotate = magnitude == 0 || Math.abs(leftPos) < 3 ? 0 : -leftPos / this.maxRadius;

        /*  SAVE FOR IMPULSES ON COLLISSIONS
        *   //const impulsePower = new CANNON.Vec3(0, 0, 5);
            //const origin = new CANNON.Vec3(0, 0, 0);
            //this.protagonist.sphereBody.applyLocalImpulse(impulsePower, this.protagonist.sphereBody.position);
        * */
    }

    getMousePosition(evt) {
        let clientX = evt.targetTouches ? evt.targetTouches[0].pageX : evt.clientX;
        let clientY = evt.targetTouches ? evt.targetTouches[0].pageY : evt.clientY;
        return { x: clientX, y: clientY };
    }

    setInstance() {
        this.protagonist = this.world.protagonist;

        /*quaternion*/
        this.rotateAngleQuaternion = Math.PI / 50;
        this.rotY = new CANNON.Quaternion(0, 0, 0, 1);

        this.roll = {
            forwards: 0,
            rotate: 0,
        };
        this.speed = 0;

        this.isMouseDown = false;

        this.controllerHolder = document.getElementById('controller-holder');
        this.controller = document.getElementById('controller');

        this.mouseDownEvent = this.clickCircle.bind(this);
        this.controller.addEventListener('mousedown', this.mouseDownEvent, false);
        this.mouseUpEvent = () => {
            this.isMouseDown = false;

            this.roll.forwards = 0;
            this.roll.rotate = 0;

            this.controller.style.left = `0px`;
            this.controller.style.top = `0px`;

            this.protagonist.sphereBody.angularDamping = 0.8;
        };

        document.addEventListener('mouseup', this.mouseUpEvent);
        this.mouseMoveEvent = this.moveCircle.bind(this);
        document.addEventListener('mousemove', this.mouseMoveEvent, false);

        this.controllerKeyDown = (evt) => {
            switch (evt.code) {
                case 'KeyA':
                case 'ArrowLeft':
                    this.roll.rotate = 1;
                    break;
                case 'KeyW':
                case 'ArrowUp':
                    this.roll.forwards = 1;
                    break;
                case 'KeyD':
                case 'ArrowRight':
                    this.roll.rotate = -1;
                    break;
                case 'KeyS':
                case 'ArrowDown':
                    this.roll.forwards = -1;
                    break;
            }
        };

        this.controllerKeyUp = (evt) => {
            switch (evt.code) {
                case 'KeyA':
                case 'ArrowLeft':
                case 'KeyD':
                case 'ArrowRight':
                    this.roll.rotate = 0;
                    break;
                case 'KeyW':
                case 'ArrowUp':
                case 'KeyS':
                case 'ArrowDown':
                    this.roll.forwards = 0;
                    break;
            }
        };

        document.addEventListener('keydown', this.controllerKeyDown);
        document.addEventListener('keyup', this.controllerKeyUp);

        /*        if (this.levelName === 'crypto') {
            console.log('is crypto')
            setTimeout(() => {
                this.gate = this.world.gate

                this.gate.on('password_console_open', this.rubbisz);
            }, 3000)

        }*/
    }

    rubbisz() {
        console.log('lol');
    }

    update() {
        let this_frame = Date.now();
        let time_delta = this_frame - this.last_frame;
        this.last_frame = this_frame;

        // move only when unpaused:
        if (!this.level.scoring.timerPaused && this.isActive) {
            let dir = new THREE.Vector3(this.protagonist.mesh.position.x, this.protagonist.mesh.position.y, this.protagonist.mesh.position.z);
            dir.sub(this.camera.instance.position).normalize(); // direction vector between the camera and the ball

            if (Math.abs(this.roll.forwards) < 0.25) {
                this.speed /= 1.1;
            }
            this.speed = Math.min(Math.max(this.speed + this.roll.forwards, -5), 10);
            let flatDir = new THREE.Vector2(dir.x, dir.z);
            flatDir.multiplyScalar(this.speed * (time_delta / 10));
            this.protagonist.sphereBody.velocity.x = flatDir.x;
            this.protagonist.sphereBody.velocity.z = flatDir.y;

            cameraRotation += (this.roll.rotate * this.rotateAngleQuaternion * (time_delta / 10)) / 5.5;

            /* align protagonist mesh with its physics body */
            this.protagonist.mesh.position.copy(this.protagonist.sphereBody.position);
            this.protagonist.mesh.quaternion.copy(this.protagonist.sphereBody.quaternion);

            var rotZ = Math.cos(cameraRotation);
            var rotX = Math.sin(cameraRotation);
            var distance = 20;

            let targetVector = new THREE.Vector3(
                this.protagonist.sphereBody.position.x - distance * rotX,
                this.protagonist.sphereBody.position.y + 10,
                this.protagonist.sphereBody.position.z - distance * rotZ
            );

            this.camera.instance.position.copy(targetVector);

            this.camera.instance.lookAt(this.protagonist.mesh.position);
        }
    }

    destroy() {
        this.controller.removeEventListener('mousedown', this.mouseDownEvent);
        delete this.mouseDownEvent;
        this.time.specificCallbackOff('tick', this.ticker);
        delete this.ticker;
        this.world.specificCallbackOff('world_ready', this.worldReady);
        delete this.worldReady;
        document.removeEventListener('mouseup', this.mouseUpEvent);
        delete this.mouseUpEvent;
        document.removeEventListener('mousemove', this.mouseMoveEvent);
        delete this.mouseMoveEvent;
        document.removeEventListener('keydown', this.controllerKeyDown);
        delete this.controllerKeyDown;
        document.removeEventListener('keyup', this.controllerKeyUp);
        delete this.controllerKeyUp;
    }
}
