/* Source of truth: https://hub.animorph.coop/f/312539 */
const sourcesPanels = {
    data: [
        {
            module: 'game-start',
            stages: [
                {
                    /*Intro triggered only on the game launch */
                    name: 'intro',
                    backdrop: '/static/images/mainframe.png',
                    image: '',
                    emoji: '🐝',
                    title: "I'm glad you're here!",
                    segments: [
                        {
                            body: 'Our Information Security Team have just detected a potential intrusion into our IT systems.',
                        },
                        {
                            body: 'UK business are being hit by a wave of cyber-attacks by the hacker known as Spider.',
                        },
                        {
                            body: 'Spider can break into our critical IT systems in under 7 minutes. We need your help to keep Spider out!',
                        },
                        {
                            body: 'Remember, you are the strongest ally to the Information Security Team.',
                        },
                        {
                            body: 'Are you ready? You have just 7 minutes to beat Spider.',
                        },
                    ],
                },
            ],
        },
        {
            module: 'mainframe',
            stages: [
                {
                    /*Intro triggered only on the game launch */
                    name: 'intro',
                    backdrop: '',
                    image: '',
                    emoji: '🐝',
                    title: 'You’ve entered the Mainframe.',
                    segments: [
                        {
                            body: 'From here you can access the three weak zones you must strengthen.',
                        },
                        {
                            body: 'Use the joy stick, WASD, or arrow keys to move around.',
                        },
                        {
                            body: 'Click the "?" if you need help.',
                        },
                        {
                            body: 'Select a zone you would like to attempt by entering a green gate. Do not delay! Spider is coming!',
                        },
                    ],
                },
                {
                    name: 'info',
                    backdrop: '',
                    image: '',
                    emoji: '🐝️',
                    title: 'Mainframe help',
                    segments: [
                        {
                            image: '',
                            body: 'The help panel which will show you relevant information for each zone. It pauses the red timer bar at the top of the screen.',
                        },
                        {
                            image: '',
                            body: 'Use the joy stick, WASD, or arrow keys to move around.',
                        },
                        {
                            image: '',
                            body: 'Now hop to it! There are three weak zones you must fix. Enter each zone and complete the challenge to patch it.',
                        },
                    ],
                },
                {
                    name: 'game-over-time-out',
                    backdrop: '',
                    image: '',
                    emoji: '🕷️',
                    title: 'Game over',
                    segments: [
                        {
                            body: 'Oh no, we have run out of time and Spider has broken into our critical IT systems. This is really bad news for our company.',
                        },
                        {
                            body: 'In the real world we won’t get a second chance. Here you can have another go so you become a stronger ally to the  Information Security Team.',
                        },
                    ],
                },
                {
                    name: 'game-over-finished',
                    backdrop: '',
                    image: '',
                    emoji: '🐝',
                    title: 'Game over!',
                    segments: [
                        {
                            body: 'Congratulations! You secured all the gates successfully and kept Spider from breaking into our critical IT systems. Thank you for being a strong ally to the Information Security Team.',
                        },
                    ],
                },
            ],
        },
        {
            module: 'behaviour',
            stages: [
                {
                    /*Intro triggered only on the game launch */
                    name: 'intro',
                    backdrop: '',
                    image: '',
                    emoji: '🐝',
                    title: "You've entered the Behaviour Zone.",
                    segments: [
                        {
                            body: 'Your task is to knock out all the behaviour blocks which pose a security risk to our IT systems, and keep all the blocks that protect our IT systems standing.',
                        },
                        {
                            body: "The gateway to the Mainframe will re-open once you've got everything right. Choose wisely, time is ticking!",
                        },
                    ],
                },
                {
                    name: 'info',
                    backdrop: '',
                    image: '',
                    emoji: '🐝',
                    title: 'Behaviour Zone help',
                    segments: [
                        {
                            image: '',
                            body: 'Your task is to knock out all the behaviour blocks which pose a security risk to our IT systems, and keep all the blocks that protect our IT systems standing.',
                        },
                        {
                            image: '/static/images/behaviours/share.png',
                            body: 'Never share your password with anyone, even friends, family members, or co-workers. This increases the chance of password breach. Your password is your secret to keep.',
                        },
                        {
                            image: '/static/images/behaviours/reuse.png',
                            body: 'Never reuse the same password across multiple accounts. If one account is hacked then all the accounts are in danger.',
                        },
                        {
                            image: '/static/images/behaviours/notes.png',
                            body: 'Never store a password in an unencrypted format, such as on a sticky note (digital or physical), in a file on your phone or computer, as an image in your mobile photo album, or as a file on a cloud storage service such as Dropbox or Google Drive.',
                        },
                        {
                            image: '/static/images/behaviours/business-card.png',
                            body: 'Never use personal details in passwords. Random words provide greater resilience.',
                        },
                        {
                            image: '/static/images/behaviours/password-manager.png',
                            body: 'Always use a password manager application.',
                        },
                        {
                            image: '/static/images/behaviours/password-multi.png',
                            body: 'Always enable multi-factor authentication on your important accounts.',
                        },
                        {
                            image: '/static/images/behaviours/regular.png',
                            body: 'Always update your passwords 2 to 4 times a year, and when you receive a notification that an account was compromised.',
                        },
                        {
                            image: '/static/images/behaviours/random.png',
                            body: 'Always use strong passwords. Length trumps complexity, so use the three-random-words format to make your password, long, strong, and easy to remember.',
                        },
                        {
                            image: '/static/images/behaviours/iot.png',
                            body: 'Always change factory default passwords. Internet-connected or “smart” devices are often protected with default passwords when you buy them. Many of these passwords (especially for accessing admin settings) can be found online. Changing a default password reduces the risk of the device being compromised.',
                        },
                        {
                            image: '/static/images/behaviours/login.png',
                            body: 'Always use screen locks to stop opportunistic individuals from accessing a device, especially if you lose it or leave it unattended.',
                        },
                    ],
                },
                {
                    name: 'feedback-exit',
                    backdrop: '',
                    image: '',
                    emoji: '🐝',
                    title: 'The gateway to the Mainframe has opened.',
                    segments: [
                        {
                            body:
                                'Leave through the gateway if you have pushed over all the behaviour blocks\n' +
                                'which pose a security risk to our IT systems, and left all the blocks that\n' +
                                'protect our IT systems standing.\n',
                        },
                    ],
                },
                {
                    name: 'feedback-positive',
                    backdrop: '',
                    image: '',
                    emoji: '🐝',
                    title: 'Great job!',
                    segments: [
                        {
                            body: 'Excellent, all the insecure behaviours have been toppled, you’ve secured the Behaviour Zone!<br><br>',
                        },
                    ],
                },
                {
                    name: 'feedback-negative',
                    backdrop: '',
                    image: '',
                    emoji: '🐝',
                    title: 'Not this time!',
                    segments: [
                        {
                            body: 'You’ve made some mistakes by leaving risky behaviours standing, or knocking over protective behaviours.',
                        },
                        {
                            body: 'Read the help information, and try the Behaviour Zone again.',
                        },
                        {
                            body: "The gateway to the Mainframe will re-open once you've got everything right Move swiftly and accurately, we must stop Spider breaching!",
                        },
                    ],
                },
            ],
        },
        /*other levels...*/
        /*(...)*/
        /*for Crypto feedback positive you will get a returned set of conditions met*/
        {
            module: 'multifactor',
            stages: [
                {
                    /*Intro triggered only on the game launch */
                    name: 'intro',
                    backdrop: '',
                    image: '',
                    emoji: '🐝',
                    title: "You've entered the Multifactor Zone.",
                    segments: [
                        {
                            body: 'If passwords are like a padlock, multi-factor authentication is like dead bolting the door that offers an extra layer of security to keep the spider out!',
                        },
                        {
                            body: 'Your task is to connect authentication methods which would effectively implement a multi-factor authentication process.',
                        },
                        {
                            body: '<ul>Connect the following sets:\n<li>- two methods that can be used in 2FA,</li><li>- three methods that can be used in 3FA.</li></ul>',
                        },
                        {
                            body: 'Connect methods by rolling into method blocks one after the other.',
                        },
                        {
                            body: "The gateway to the Mainframe will re-open once you've connected the sets correctly. Take care, you're racing Spider!",
                        },
                    ],
                },
                {
                    name: 'info',
                    backdrop: '',
                    image: '',
                    emoji: '🐝',
                    title: 'Multi-factor Zone help',
                    segments: [
                        {
                            image: '',
                            body: 'Your task is to connect authentication methods which would effectively implement a multi-factor authentication process.',
                        },
                        {
                            image: '',
                            body: '<ul>Connect the following sets:\n<li>- two methods that can be used in 2FA,</li><li>- three methods that can be used in 3FA.</li></ul>',
                        },
                        {
                            image: '',
                            body: 'Connect methods by rolling into method blocks one after the other.',
                        },
                        {
                            image: '',
                            body: "The gateway to the Mainframe will re-open once you've connected the sets correctly. Take care, you're racing Spider!",
                        },
                        {
                            image: '',
                            body: '<h2 class="font-bold">Why use multi-factor authentication?</h2>',
                        },
                        {
                            image: '',
                            body: "Even a strong password isn't completely secure if it is the only method of authentication.",
                        },
                        {
                            image: '',
                            body: 'Passwords can be cracked by hackers using sophisticated software and machines.',
                        },
                        {
                            image: '',
                            body: 'People can be susceptible to social engineering, such as phishing, and give their password to hackers.',
                        },
                        {
                            image: '',
                            body: 'Adding an extra factor of authentication makes access more difficult for a hacker, and so your data safer.',
                        },
                        {
                            image: '',
                            body: 'Strong sets of multi-factor authentication combine the different types of three factors:',
                        },
                        {
                            image: '/static/images/factors/1-password.png',
                            body: 'Knowledge (something you know) like a password, security question, or pin code.',
                        },
                        {
                            image: '/static/images/factors/6-token.png',
                            body: 'Possession, (something you have) like a smartphone, smart card, or hardware token.',
                        },
                        {
                            image: '/static/images/factors/7-facial-recognition.png',
                            body: 'Inherence (something you have) like a fingerprint, retina, or face.',
                        },
                    ],
                },
                {
                    name: 'feedback-pair',
                    backdrop: '',
                    image: '',
                    emoji: '🐝',
                    title: 'You have completed a valid pair.',
                    segments: [
                        {
                            body: 'These two methods make up a secure two-factor authentication system. Now select <span class="font-bold">three</span> of the remaining antennae to make a three-factor system.',
                        },
                    ],
                },
                {
                    name: 'feedback-exit',
                    backdrop: '',
                    image: '',
                    emoji: '🐝',
                    title: 'You have completed a valid triple.',
                    segments: [
                        {
                            body: 'Perfect, all the sets you connected would make strong multi-factor sets, you’ve secured the Multi-factor Zone! The gateway to return to Mainframe has opened.',
                        },
                    ],
                },
                {
                    name: 'feedback-mistake',
                    emoji: '🐝',
                    title: 'This is not a valid set.',
                    segments: [
                        {
                            body: 'You’ve made a mistake in your connected set of methods. Read the help information, and try again. Take care to make the correct links, Spider is getting closer!',
                        },
                    ],
                },
            ],
        },
        {
            module: 'crypto',
            stages: [
                {
                    /*Intro triggered only on the game launch */
                    name: 'intro',
                    backdrop: '',
                    image: '',
                    emoji: '🐝',
                    title: "You've entered the Crypto Zone.",
                    segments: [
                        {
                            body: 'Your first line of defense against Spider’s attack is a powerful password!',
                        },
                        {
                            body: 'Your task is to roll to the terminal and enter the strongest password you can possibly think of.',
                        },
                        {
                            body: 'The gateway to the Mainframe will re-open once you’ve entered a password that meets all the conditions for a strong password.',
                        },
                        {
                            body: 'Let us see if you have got what it takes to make a strong password.',
                        },
                        {
                            body: 'Get cracking, Spider is getting closer!',
                        },
                    ],
                },
                {
                    name: 'info',
                    backdrop: '',
                    image: '',
                    emoji: '🐝️',
                    title: 'Crypto Zone help',
                    segments: [
                        {
                            image: '',
                            body: 'Your task is to roll to the terminal and enter the strongest password you can possibly think of. The gateway to the Mainframe will re-open once you’ve entered a password that meets all the conditions for a strong password.',
                        },
                        {
                            image: '',
                            body: '<h2 class="font-bold">What is a password?</h2>',
                        },
                        {
                            image: '',
                            body: '<ul><li>"OPEN SESAME!" — Ali Baba</li>' + '<li>A secret word or phrase used to gain admission to a place.</li></ul>',
                        },
                        {
                            image: '',
                            body: '<ul><li>0p3n5ES4Me:?</li>' + '<li>A string of characters that allows access to a computer system or service.</li></ul>',
                        },
                        {
                            image: '',
                            body: '<h2 class="font-bold">Strong passwords</h2>',
                        },
                        {
                            image: '',
                            body:
                                'Strong passwords are:' +
                                '<ul><li>— at least 12 characters long</li>' +
                                '<li>— a mixture of numbers, uppercase and lowercase letters, and other symbols, eg !@#£$</li>' +
                                '<li>— impossible to guess, but memorable for you</li></ul>',
                        },
                        {
                            image: '',
                            body: '<h2 class="font-bold">Weak passwords</h2>',
                        },
                        {
                            image: '',
                            body:
                                'Weak passwords are:' +
                                '<ul><li>— fewer than 8 characters long</li>' +
                                '<li>— dictionary words without numbers or symbols</li>' +
                                '<li>— generic combinations, e.g. qwerty, letmein!, password123</li>' +
                                "<li>— your date of birth, partner's name, or other personal information</li></ul>",
                        },
                        {
                            image: '',
                            body: 'What a mountain to climb! There are two strong options here.</h2>',
                        },
                        {
                            image: '',
                            body: '<h2 class="font-bold">Three-random-words</h2>',
                        },
                        {
                            image: '',
                            body:
                                'Create a password following the three-random-word method:' +
                                '<ul><li>— Combine three random words to create a password that’s ‘long enough and strong enough’, and can be remembered much more easily.</li>' +
                                '<li>— Add some numbers and symbols to it to make it stronger.</li>' +
                                '<li>— eg 1eyeball6APPLESbounce! (three random words mixed with numbers and sybmols – 1 eyeball; 6 apples; bounce!)</li></ul>',
                        },
                        {
                            image: '',
                            body: '<h2 class="font-bold">Password manager</h2>',
                        },
                        {
                            image: '',
                            body:
                                'Use password manager software to store passwords securely:' +
                                '<ul><li>— People have lots of passwords to remember, both personally and for work, and memorising all of them is impossible. To cope with this overload, sometimes people resort to insecure practices, such as weak passwords or reusing the same passwords. This is exploited by attackers. Password managers are a secure way of coping with password overload.</li>' +
                                "<li>— Password managers help users create and store the credentials they use to access services, typically a username and password. The credentials are securely stored in a 'vault', which is accessed via a master password.</li></ul>",
                        },
                    ],
                },
                {
                    name: 'feedback-exit',
                    backdrop: '',
                    image: '',
                    emoji: '🐝',
                    title: 'The gateway to the Mainframe has opened.',
                    segments: [
                        {
                            body: 'You have solved the crypto challenge and you can return to the mainframe!',
                        },
                    ],
                },
                {
                    name: 'feedback-positive',
                    backdrop: '',
                    image: '',
                    emoji: '🐝',
                    title: 'Great job!',
                    segments: [
                        {
                            body: 'Excellent, all the insecure behaviours have been toppled, you’ve secured the Behaviour Zone!<br><br>',
                        },
                    ],
                },
            ],
        },

        /*panels with results*/
        {
            module: 'universal',
            stages: [],
        },
    ],
};

export default sourcesPanels;
