import * as THREE from 'three';

import Level from '../level.js';

export default class Renderer {
    constructor() {
        this.level = new Level();
        this.sizes = this.level.sizes;
        this.scene = this.level.scene;
        this.canvas = this.level.canvas;

        this.camera = this.level.camera;

        this.setInstance();
    }

    setInstance() {
        /*        this.instance = new THREE.WebGLRenderer({
            canvas: this.canvas,
            antialias: true
        })*/
        this.instance = new THREE.WebGLRenderer({ antialias: true });

        this.canvas.appendChild(this.instance.domElement);
        this.instance.physicallyCorrectLights = true;
        this.instance.outputEncoding = THREE.sRGBEncoding;
        this.instance.toneMapping = THREE.CineonToneMapping;
        this.instance.toneMappingExposure = 1.75;
        this.instance.shadowMap.enabled = true;
        this.instance.shadowMap.type = THREE.PCFSoftShadowMap;
        this.instance.setSize(this.sizes.width, this.sizes.height);
        this.instance.setPixelRatio(Math.min(this.sizes.pixelRatio, 2));
    }
    resize() {
        this.instance.setSize(this.sizes.width, this.sizes.height);
        this.instance.setPixelRatio(Math.min(this.sizes.pixelRatio, 2));
    }

    update() {
        this.instance.render(this.scene, this.camera.instance);
    }
}
