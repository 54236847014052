import Debug from './utils/debug.js';
import Level from './level.js';
import Sizes from './utils/sizes.js';
import Time from './utils/time.js';
import Scoring from './components/scoring.js';
import Destroyable from './utils/destroyable';

let gameInstance = null;

export const TOTAL_GAME_MINUTES = 7;

export default class Game {
    constructor(gameCanvas) {
        // singleton
        if (gameInstance) {
            return gameInstance;
        }
        gameInstance = this;

        this.debug = new Debug();

        this.canvas = gameCanvas;

        this.mainPanel = document.getElementById('main-overlay');

        /* Shouldn't playing with classList happen somewhere else?*/
        this.mainPanel.classList.remove('hidden');

        this.sizes = new Sizes();
        this.time = new Time();
        this.scoring = new Scoring();

        this.startLevel('mainframe');

        this.scoring.startLevelMainframe();

        this.timerBar = document.getElementById('timer-bar');

        this.sizes.on('resize', () => {
            this.resize();
        });

        this.time.on('tick', () => {
            this.update();
        });
    }

    startLevel(levelName) {
        if (!this.currentLevel) {
            this.currentLevel = new Level(levelName);
        } else {
            console.log('level not properly cleared');
        }
        this.scoring.timerPause();
        this.scoring.timerUnpause();
    }

    /*TODO: This method is actually about terminating a level
     * so it should first show the results, decide and
     * then push the user in the new direction
     * */
    startNewLevel(movingToLevel) {
        /*In preparation for the new level, need to clear panelCount to ensure consistency for intro panel rendering which is different than info*/
        //this.mainPanel.dispatchEvent(new CustomEvent('levelend'));

        //window.dispatchEvent(new Event('levelend'));

        if (this.currentLevel.name !== 'mainframe') {
            /* level win scenario
             * flip below to WIN
             */
            if (this.scoring.globalScore[this.currentLevel.name]) {
                this.clearLevel();
                // returning to mainframe
                this.startLevel('mainframe');

                // unpause the timer
                this.scoring.timerUnpause();
            } else {
                /* Bad results, have to repeat the level*/
                if (this.currentLevel.name === 'behaviour') {
                    this.clearLevel();
                    this.startLevel('behaviour');
                    this.scoring.startScoringLevel('behaviour');
                } else if (this.currentLevel.name === 'multifactor') {
                    this.clearLevel();
                    this.startLevel('multifactor');
                    this.scoring.startScoringLevel('multifactor');
                }
                /*TODO: IRON out the login including crypto*/
            }

            //sorry if not
        } else {
            /* Start of new event dispatching info for the intro panel */
            this.mainPanel.dispatchEvent(new CustomEvent('levelevent', { detail: { module: movingToLevel, stage: 'intro' } }));

            /* Shouldn't playing with classList happen somewhere else?*/
            this.mainPanel.classList.remove('hidden');
            /*Currently exiting mainframe
             * TODO: Add logic based on door names (door could have the same name/id
             *  to go to the right Zone!
             * */
            this.clearLevel();
            this.startLevel(movingToLevel);
            this.scoring.startScoringLevel(movingToLevel);
        }
    }

    clearLevel() {
        Destroyable.destroyAll(this.currentLevel.name);
        this.currentLevel = null;
    }

    getGlobalScore() {
        if (this.scoring.globalScore['application'] && this.scoring.globalScore['awareness'] && this.globalScore['application']) {
            return true;
        }
        return false;
    }

    resize() {
        if (this.currentLevel) {
            this.currentLevel.camera.resize();
            this.currentLevel.renderer.resize();
        }
    }

    showPanel(module, panelname) {
        this.mainPanel.dispatchEvent(new CustomEvent('levelevent', { detail: { module: module, stage: panelname } }));
        this.mainPanel.classList.remove('hidden');
        this.scoring.timerPause();
        var backtoinfo = (e) => {
            // switch back to the level info panel afterwards
            this.mainPanel.dispatchEvent(new CustomEvent('levelevent', { detail: { module: module, stage: 'info' } }));
            // stop these from building up
            document.getElementById('resume-button').removeEventListener('click', backtoinfo);
        };
        document.getElementById('resume-button').addEventListener('click', backtoinfo);
    }

    update() {
        let millisecondsPassed = this.scoring.getTimer();
        let secondsRemaining = TOTAL_GAME_MINUTES * 60 - Math.round(millisecondsPassed / 1000);

        // top of the viewport timer bar
        this.timerBar.style.width = `${(100 * secondsRemaining) / (TOTAL_GAME_MINUTES * 60)}%`;

        // console
        let mins = Math.floor(secondsRemaining / 60);
        let secs = String(secondsRemaining - 60 * mins).padStart(2, '0');

        // time is up
        const totalMillisecondsAllowed = TOTAL_GAME_MINUTES * 60 * 1000;
        if (millisecondsPassed > totalMillisecondsAllowed && !this.scoring.timerPaused) {
            console.log('time is up');
            this.scoring.report('game-over-time-out');
            // pause timer so that we don't send millions of requests to the backend
            this.scoring.timerPause();
            this.showPanel('mainframe', 'game-over-time-out');
        }
    }
}
