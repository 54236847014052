import * as THREE from 'three';
import Level from '../level.js';
import Destroyable from '../utils/destroyable';

export default class SpiderShadow extends Destroyable {
    constructor() {
        super();
        this.level = new Level();
        this.scene = this.level.scene;
        this.resources = this.level.resources;
        this.time = this.level.time;
        this.clock = new THREE.Clock();

        this.speed = 3; // 3js units per second
        this.fromPosition = new THREE.Vector2(15 - Math.random() * 30, 15 - Math.random() * 30);
        this.toPosition = this.fromPosition.clone();
        this.startTime = Date.now();
        this.fullTime = 1;
        this.wait = false;

        this.tick = () => {
            this.update();
        };
        this.time.on('tick', this.tick);

        this.addModelSpider();
    }

    addModelSpider() {
        const sm = this.resources.items.spider;
        const spider = sm.scene;
        this.animator = new THREE.AnimationMixer(spider);
        this.walkAnimation = this.animator.clipAction(THREE.AnimationClip.findByName(sm.animations, 'Walk'));
        this.spiderShadowChild = spider.children[0].children[1];
        this.spiderShadowChild.castShadow = true;
        console.log(spider);
        this.scene.add(spider);
        spider.position.y = 15;
        this.mesh = spider;
    }

    update() {
        if (this.animator && this.mesh) {
            this.animator.update(this.clock.getDelta());

            if (!this.level.scoring.timerPaused) {
                if (this.wait) {
                    if (Date.now() > this.wait) {
                        this.wait = false;
                        this.walkAnimation.play();
                        this.startTime = Date.now();
                        this.fromPosition = this.toPosition.clone();
                        this.toPosition = new THREE.Vector2(15 - Math.random() * 30, 15 - Math.random() * 30);
                        const newPath = this.toPosition.clone().sub(this.fromPosition.clone());
                        this.mesh.rotation.y = Math.atan2(newPath.x, newPath.y) + Math.PI;
                        this.fullTime = (1000 * this.fromPosition.distanceTo(this.toPosition)) / this.speed;
                    }
                } else {
                    const delta = Date.now() - this.startTime;
                    const proportion = delta / this.fullTime;
                    this.mesh.position.x = this.fromPosition.x + (this.toPosition.x - this.fromPosition.x) * proportion;
                    this.mesh.position.z = this.fromPosition.y + (this.toPosition.y - this.fromPosition.y) * proportion;
                    if (proportion >= 1) {
                        this.wait = Date.now() + Math.random() * 1500;
                        this.walkAnimation.stop();
                    }
                }
            }
        }
    }

    debugLine(from, to, c) {
        const l = new THREE.Line(new THREE.BufferGeometry().setFromPoints([new THREE.Vector3(from.x, 0.15, from.y), new THREE.Vector3(to.x, 0.15, to.y)]), new THREE.LineBasicMaterial({ color: c }));
        this.scene.add(l);
        return l;
    }

    // useful at the end of the game if we were successful
    disableSpiderShadow() {
        this.spiderShadowChild.castShadow = false;
    }

    TextureAnimator(texture, tilesHoriz, tilesVert, tileDispDuration) {
        // https://stackoverflow.com/questions/16029103/three-js-using-2d-texture-sprite-for-animation-planegeometry
        let obj = {};

        obj.texture = texture;
        obj.tilesHorizontal = tilesHoriz;
        obj.tilesVertical = tilesVert;
        obj.tileDisplayDuration = tileDispDuration;

        obj.numberOfTiles = tilesHoriz * tilesVert;

        obj.texture.wrapS = THREE.RepeatWrapping;
        obj.texture.wrapT = THREE.RepeatWrapping;
        obj.texture.repeat.set(1 / tilesHoriz, 1 / tilesVert);
        obj.currentTile = 0;

        obj.nextFrame = function () {
            if (!this.level.scoring.timerPaused) {
                // this line added so it respects pauses
                obj.currentTile++;
                if (obj.currentTile == obj.numberOfTiles) {
                    obj.currentTile = 0;
                }

                let currentColumn = obj.currentTile % obj.tilesHorizontal;
                obj.texture.offset.x = currentColumn / obj.tilesHorizontal;

                let currentRow = Math.floor(obj.currentTile / obj.tilesHorizontal);
                obj.texture.offset.y = obj.tilesVertical - currentRow / obj.tilesVertical;
            }
        }.bind(this);

        obj.start = function () {
            obj.intervalID = setInterval(obj.nextFrame, obj.tileDisplayDuration);
        };

        obj.stop = function () {
            clearInterval(obj.intervalID);
        };
        obj.start();
        return obj;
    }
}
