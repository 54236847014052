function checkPassword(password) {
    // this should probably go in data?
    let checks = {
        length: (pwd) => {
            return pwd.length > 11;
        },
        lowercase: (pwd) => {
            for (var i = 0; i < pwd.length; i++) {
                let code = pwd.charCodeAt(i);
                if (code >= 97 && code <= 122) {
                    return true;
                }
            }
            return false;
        },
        uppercase: (pwd) => {
            for (var i = 0; i < pwd.length; i++) {
                let code = pwd.charCodeAt(i);
                if (code >= 65 && code <= 90) {
                    return true;
                }
            }
            return false;
        },
        numbers: (pwd) => {
            for (var i = 0; i < pwd.length; i++) {
                let code = pwd.charCodeAt(i);
                if (code >= 48 && code <= 57) {
                    return true;
                }
            }
            return false;
        },
        nonalpha: (pwd) => {
            for (var i = 0; i < pwd.length; i++) {
                let code = pwd.charCodeAt(i);
                if (code <= 47 || (code >= 59 && code <= 64) || (code >= 91 && code <= 96) || code >= 123) {
                    return true;
                }
            }
            return false;
        },
    };
    let conditionsMet = [];
    for (const [description, check] of Object.entries(checks)) {
        if (check(password)) {
            conditionsMet.push(description);
        }
    }

    return evaluatePopularity(password).then((popularity) => {
        //console.log(popularity)
        if (popularity === 'rare') {
            conditionsMet.push('rare');
        }
        return finishPasswordCheck(checks, conditionsMet);
    });
}

/* Separate function to call the backend to see if the password is popular*/
function evaluatePopularity(password) {
    return fetch('/game/check_password/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            // eslint-disable-next-line no-undef
            'X-CSRFToken': CSRFTOKEN,
        },
        body: password,
    }).then((response) => {
        // nested promise returns the value of text from HttpResponse:
        // https://developer.mozilla.org/en-US/docs/Web/API/Response/text
        return response.text().then((text) => {
            return text;
        });
    });
}

function finishPasswordCheck(checks, conditionsMet) {
    let result = {
        conditionsMet: conditionsMet,
    };
    //succeeded summary evaluation by the length
    result['succeeded'] = conditionsMet.length === Object.keys(checks).length + 1;
    return result;
}

export { checkPassword };
