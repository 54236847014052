import sourcesPanels from '../data/sourcesPanels.js';

/* Intro & Info Panel Logic for the Game */

/*Levels have different structures, has segments in intro while others don't, other levels might have different info structures; also mainframe doesn't have pre-outro and outro */

let previousEventsData = [];

function getPanelSourcesData(currentLevel, currentStage) {
    let levelObject;

    Object.values(sourcesPanels).forEach((levels) => {
        levels.forEach((level) => {
            if (level.module === currentLevel) {
                level.stages.forEach((stage) => {
                    if (stage.name === currentStage) {
                        //stage.push('segment_count': stage.segments.length)

                        levelObject = stage;
                        //levelObject.push({segment_count: stage.segments.length})
                        levelObject = Object.assign(
                            {
                                module: level.module,
                                segment_count: stage.segments.length,
                            },
                            levelObject
                        );
                    }
                });
            }
        });
    });
    return levelObject;
}

function getLevelData(eventDetail) {
    let currentLevelName = eventDetail.module;
    let currentStageName = eventDetail.stage;

    setTimeout(() => {
        openInfoPanel();
    }, 100);

    return getPanelSourcesData(currentLevelName, currentStageName);
}

function closePanelSequence(elementPressed) {
    //TODO: Check if contains

    if (document.getElementById('game').classList.contains('hidden')) {
        document.getElementById('game').classList.remove('hidden');
    }

    /* more information appears only in the scored levels */
    if (elementPressed !== document.getElementById('more-information-button')) {
        document.getElementById('main-overlay').classList.add('hidden');
        document.getElementById('top-bar').classList.remove('hidden');
    }

    /*Check if this was intro panel in which case we need to preload info panel
     * skip vs start buttons
     * */

    if (elementPressed !== document.getElementById('resume-button')) {
        checkLastEvent();
    }

    return 0;
}

/*Due to the fact that game-start has only intro we need to hack around*/
function openInfoPanel() {
    if (previousEventsData[previousEventsData.length - 1].stage === 'intro') {
        document.getElementById('main-overlay').classList.remove('hidden');
    }
}

/* Load first panel */
window.onload = function () {
    let mainPanel = document.getElementById('main-overlay');
    if (typeof mainPanel != 'undefined' && mainPanel != null) {
        const gameStartEvent = new CustomEvent('levelevent', {
            detail: {
                module: 'game-start',
                stage: 'intro',
            },
        });

        mainPanel.addEventListener('levelevent', (event) => {
            saveLastLevelEvent(event.detail);
        });

        mainPanel.dispatchEvent(gameStartEvent);
    }
};

function saveLastLevelEvent(eventData) {
    let newEventData = {
        module: eventData.module,
        stage: eventData.stage,
    };

    previousEventsData.push(newEventData);
}

/* Custom event logic lair */
function checkLastEvent() {
    /* check if this is intro so we can load info */
    if (previousEventsData[previousEventsData.length - 1].stage === 'intro') {
        if (previousEventsData[previousEventsData.length - 1].module === 'game-start') {
            document.getElementById('main-overlay').dispatchEvent(
                new CustomEvent('levelevent', {
                    detail: {
                        module: 'mainframe',
                        stage: 'intro',
                    },
                })
            );
        } else if (previousEventsData[previousEventsData.length - 1].module === 'mainframe') {
            document.getElementById('main-overlay').dispatchEvent(
                new CustomEvent('levelevent', {
                    detail: {
                        module: 'mainframe',
                        stage: 'info',
                    },
                })
            );
        } else if (previousEventsData[previousEventsData.length - 1].module === 'behaviour') {
            document.getElementById('main-overlay').dispatchEvent(
                new CustomEvent('levelevent', {
                    detail: {
                        module: 'behaviour',
                        stage: 'info',
                    },
                })
            );
            //document.getElementById('more-information-button').classList.remove('hidden');
        } else if (previousEventsData[previousEventsData.length - 1].module === 'multifactor') {
            document.getElementById('main-overlay').dispatchEvent(
                new CustomEvent('levelevent', {
                    detail: {
                        module: 'multifactor',
                        stage: 'info',
                    },
                })
            );
        }
        //previousEventsData[previousEventsData.length - 1].module === 'crypto'
        else {
            document.getElementById('main-overlay').dispatchEvent(
                new CustomEvent('levelevent', {
                    detail: {
                        module: 'crypto',
                        stage: 'info',
                    },
                })
            );
        }
    }
}

export { getLevelData, closePanelSequence };
