import Time from '../utils/time.js';
import EventEmitter from '../utils/eventEmitter.js';
import { logLevelAnalytic, logGenericAnalytic } from '../analytics.js';

let scoringInstance = null;

export default class Scoring extends EventEmitter {
    constructor() {
        // singleton
        if (scoringInstance) {
            return scoringInstance;
        }
        super();
        scoringInstance = this;
        this.time = new Time();
        this.levelName = '';

        this.succeded = false;

        /* will flip to true if successful */
        this.globalScore = {
            behaviour: false, // behind the player
            multifactor: false, // ahead of the player
            crypto: false, // to the right of the player
            // compliance not needed now, but has a dummy equivalent in environment as physics need to roll around
        };

        /*TODO: Revert & map levels onto the below when we hear back from Chitra*/
        /*this.globalScore = {
            conduct: false,
            awareness: false,
            application: false,
        };*/

        /*starting value*/
        this.levelsCompleted = 0;

        // this.showDebuggingPanel();
    }

    startLevelMainframe() {
        this.startTime = Date.now();
        this.timerPaused = this.startTime;
        this.pauseDuration = 0;

        this.report('game-start');

        setTimeout(() => {
            document.getElementById('info-button').addEventListener('click', () => {
                if (this.timerPaused) {
                    this.timerUnpause();
                } else {
                    this.timerPause();
                }
            });

            document.getElementById('start-level-button').addEventListener('click', () => {
                this.timerUnpause();
            });
            document.getElementById('skip-button').addEventListener('click', () => {
                this.timerUnpause();
            });
            document.getElementById('resume-button').addEventListener('click', () => {
                this.timerUnpause();
            });
        }, 100);
    }

    startScoringLevel(levelName) {
        this.levelName = levelName;
        if (levelName === 'behaviour') {
            this.obelisksHit = 0;
            this.intersectedObelisks = [];

            this.badBehavioursHit = 0;
            this.goodBehavioursHit = 0;

            /* Testing UI feedback handling */
            /*            setTimeout(() => {
                            this.trigger('intersected_all');
                        }, 5000);*/
        } else if (levelName === 'multifactor') {
            //TODO: Write logic
        } else if (levelName === 'crypto') {
            //TODO: Write logic
        }

        /*        document.getElementById('start-behaviour-button').addEventListener('click', () => {
                    this.timerUnpause();
                });*/
    }

    getIntersectedObelisks() {
        return this.intersectedObelisks;
    }

    clearIntersectedObelisks() {
        // if the level is reloaded, the obelisks get new ids
        this.intersectedObelisks = [];
    }

    addIntersectedObelisks(obeliskObject) {
        let intersectedAlready = false;
        // add to the collection only if it has not intersected previously
        this.intersectedObelisks.forEach((obeliskMesh) => {
            if (obeliskObject.id === obeliskMesh.id) {
                intersectedAlready = true;
            }
        });
        if (!intersectedAlready) {
            this.intersectedObelisks.push(obeliskObject);
            if (this.intersectedObelisks.length > 13) {
                this.trigger('intersected_all');
            }
        }
    }

    timerPause() {
        //console.log('timer paused');
        if (!this.timerPaused) {
            this.timerPaused = Date.now();
            this.trigger('paused_timer');
        }
    }

    timerUnpause() {
        //console.log('timer unpaused');
        if (this.timerPaused) {
            this.pauseDuration += Date.now() - this.timerPaused;
            this.timerPaused = false;
            this.trigger('unpaused_timer');
        }
    }

    getTimer() {
        if (this.startTime) {
            if (this.timerPaused) {
                return this.timerPaused - this.startTime - this.pauseDuration;
            } else {
                // milliseconds that have passed
                return Date.now() - this.startTime - this.pauseDuration;
            }
        } else {
            // hasn't started yet
            return 0;
        }
    }

    obeliskCollided(hitGood) {
        if ('obelisksHit' in this) {
            this.obelisksHit++;
            // if you hit even one good behaviour you lose
            if (hitGood) {
                console.log('sorry');
                //this.succeded = false;
                this.goodBehavioursHit++;
            } else {
                console.log('good job');
                this.badBehavioursHit++;
            }
        }
    }

    startedCryptoChallenge() {
        console.log('recorded start of the crypto password panel + timestamp');
        this.trigger('password_challenge_active');
    }

    //TODO: Currently just for behaviour, extend to other levels
    getResult() {
        if (this.levelName == 'behaviour') {
            // there is 7 bad behaviour obelisks
            this.succeded = this.badBehavioursHit > 6 && this.goodBehavioursHit < 1;
            return this.succeded;
        } else if (this.levelName === 'multifactor' || this.levelName === 'crypto') {
            // you can't reach the door until you've finished, so you've definitely succeeded if this is called
            this.succeded = true;
            return true;
        }
    }

    report(levelName, success = null) {
        if (success === null) {
            const kind = levelName;
            logGenericAnalytic(kind);
            return;
        }
        // one of 'conduct', 'awareness', 'application'
        this.globalScore[levelName] = success;
        if (success) {
            // don't log failures because they don't make sense for most levels anyway
            logLevelAnalytic(levelName, success);
        }
        this.levelsCompleted++;
    }

    showDebuggingPanel() {
        this.debugDisplay = {};
        this.debugDisplay.wrapper = document.createElement('div');
        this.debugDisplay.wrapper.className = 'flex justify-left';
        this.debugDisplay.div = document.createElement('div');
        this.debugDisplay.div.className = 'z-20 absolute max-w-[30rem] p-1 mt-[12vh]';
        this.debugDisplay.div.className += ' text-gray-50 backdrop-blur-sm rounded-lg border border-gray-50';
        this.debugDisplay.wrapper.appendChild(this.debugDisplay.div);

        this.time.on('tick', () => {
            this.debugDisplay.div.innerHTML = '<p><b>SCORING</b></p>';
            this.debugDisplay.div.innerHTML += `<p>time: ${Math.round(this.getTimer() / 1000)} sec</p>`;
            this.debugDisplay.div.innerHTML += `<p>intersectedObelisks: ${this.intersectedObelisks.length}</p>`;
            this.debugDisplay.div.innerHTML += `<p>badBehavioursHit: ${this.badBehavioursHit}</p>`;
            this.debugDisplay.div.innerHTML += `<p>succeded: ${this.succeded}</p>`;
        });

        const game = document.getElementById('game');
        game.insertBefore(this.debugDisplay.wrapper, game.firstChild);
    }
}
