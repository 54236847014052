import * as THREE from 'three';
import Level from '../level.js';
export default class Flashlight {
    constructor() {
        this.level = new Level();
        this.scene = this.level.scene;
        this.world = this.level.world.world;

        const geometry = new THREE.CylinderGeometry(0.001, 0.05, 3, 8);
        const material = new THREE.MeshBasicMaterial({ color: 0xffffff });
        const cylinder = new THREE.Mesh(geometry, material);
        this.level.scene.add(cylinder);
        this.level.camera.instance.attach(cylinder);
        cylinder.position.set(0, 1.4, -11);
        cylinder.rotateX(Math.PI / 3);
    }
}
