import * as THREE from 'three';
import Level from '../level.js';
import Scoring from '../components/scoring.js';
import * as CANNON from 'cannon-es';
import { generatePositions } from '../utils/positionGenerator.js';
import Destroyable from '../utils/destroyable';
//import { getPolyhedronShape } from '../utils/meshToConvex';

export default class Obelisk extends Destroyable {
    constructor(levelName) {
        super(levelName);
        this.level = new Level();
        this.scoring = new Scoring();
        this.scene = this.level.scene;
        this.world = this.level.world.world;
        this.resources = this.level.resources;

        this.time = this.level.time;

        //this.targetPosition = targetPosition
        this.obelisksPositions = generatePositions(14, 2);
        this.obelisksMeshes = [];
        this.obelisksBodies = [];

        this.setModel();

        this.tick = () => {
            this.update();
        };
        this.time.on('tick', this.tick);
    }

    setAudio() {
        this.hitSound = this.resources.items.positiveBlip;
        //      this.hitSound.play();
    }

    /*
        setMesh() {
            this.mesh = new THREE.Mesh(this.geometry, this.material);

            this.mesh.position.set(0, 10, 15);

            this.scene.add(this.mesh);
        }
    */

    setModel() {
        this.obeliskModel = this.resources.items.obelisk;
        let obeliskMesh = new THREE.Object3D();

        this.obeliskModel.scene.traverse((child) => {
            if (child.name === 'Scene') {
                /*
                 * there is only one object in the group,
                 * avoiding more unpacking later by using the child
                 */
                const m = child.children[0];
                obeliskMesh = m;
            }
        });

        for (let i = 0; i <= this.obelisksPositions.length; i++) {
            if (this.obelisksPositions[i] instanceof THREE.Vector3) {
                let obeliskMeshClone = obeliskMesh.clone();
                obeliskMeshClone.position.x = this.obelisksPositions[i].getComponent(0);
                obeliskMeshClone.position.y = this.obelisksPositions[i].getComponent(1);
                obeliskMeshClone.position.z = this.obelisksPositions[i].getComponent(2);

                let id = i + 1;
                obeliskMeshClone.userData.textId = id;
                for (const [key, value] of Object.entries(this.resources.items)) {
                    if (id === Number(key)) {
                        obeliskMeshClone.material = this.createMaterial(value);
                    }
                }

                this.scene.add(obeliskMeshClone);

                this.obelisksMeshes.push(obeliskMeshClone);
                this.setBody(obeliskMeshClone, this.obelisksPositions[i]);
            }
        }
    }

    createMaterial(texture) {
        let behaviourMaterial = new THREE.MeshStandardMaterial({
            map: texture,
        });

        behaviourMaterial.wrapS = THREE.RepeatWrapping; // horizontal wrapping
        behaviourMaterial.wrapT = THREE.ClampToEdgeWrapping; // vertical wrapping

        texture.repeat.set(1.25, 2); // horizontal, vertical
        texture.offset.set(0, 0.05); // x, y
        texture.center.set(0.5, 0.5);
        texture.rotation = THREE.MathUtils.degToRad(180);
        texture.minFilter = THREE.LinearFilter;

        // If texture is used for color information, set colorspace.
        behaviourMaterial.encoding = THREE.sRGBEncoding;
        // UVs use the convention that (0, 0) corresponds to the upper left corner of a texture.
        behaviourMaterial.flipY = false;
        return behaviourMaterial;
    }

    setBody(meshBase, meshPosition) {
        //let collisionConvex = getPolyhedronShape(meshBase);
        // const axisSize = 1.25;
        const vectorSize = new CANNON.Vec3(1.25, 2.1, 1.25);
        let obeliskBody = new CANNON.Body({
            mass: 7,
            shape: new CANNON.Box(vectorSize),
        });

        //obeliskBody.addShape(collisionConvex);
        obeliskBody.position.set(meshPosition.getComponent(0), meshPosition.getComponent(1), meshPosition.getComponent(2));
        this.world.addBody(obeliskBody);
        this.obelisksBodies.push(obeliskBody);

        let obeliskCollisionListener = (e) => {
            if (e.body == this.level.world.protagonist.sphereBody) {
                let isGood = this.resources.textSources[meshBase.userData.textId].good;
                this.scoring.obeliskCollided(isGood);
                obeliskBody.removeEventListener('collide', obeliskCollisionListener);
            }
        };

        obeliskBody.addEventListener('collide', obeliskCollisionListener);
    }

    update() {
        for (let i = 0; i <= this.obelisksMeshes.length; i++) {
            if (this.obelisksBodies[i]) {
                this.obelisksMeshes[i].position.copy(this.obelisksBodies[i].position);
                this.obelisksMeshes[i].quaternion.copy(this.obelisksBodies[i].quaternion);
            }
        }
    }

    destroy() {
        this.time.specificCallbackOff('tick', this.tick);
    }
}
