import Level from '../level.js';
import * as CANNON from 'cannon-es';

export default class Floor {
    constructor() {
        this.level = new Level();
        this.scene = this.level.scene;
        this.resources = this.level.resources;
        this.world = this.level.world.world;

        // Setup
        //this.setGeometry();
        //this.setTextures();
        //this.setMaterial();
        //this.setMesh();
        this.setBody();
    }

    /*
        setGeometry() {
            this.geometry = new THREE.CircleGeometry(50, 128);
        }*/

    /*    setTextures() {
            this.textures = {};

            this.textures.color = this.resources.items.floorTexture;
            this.textures.color.encoding = THREE.sRGBEncoding;
            this.textures.color.repeat.set(3, 3);
            this.textures.color.wrapS = THREE.RepeatWrapping;
            this.textures.color.wrapT = THREE.RepeatWrapping;
        }*/

    /*    setMaterial() {
            this.material = new THREE.MeshBasicMaterial({
                map: this.textures.color,
                //normalMap: this.textures.normal
            });
        }*/

    /*    setMesh() {
            this.mesh = new THREE.Mesh(this.geometry, this.material);
            this.mesh.rotation.x = -Math.PI * 0.5;
            this.mesh.receiveShadow = true;
            this.scene.add(this.mesh);
        }*/

    setBody() {
        const planeBody = new CANNON.Body({
            type: CANNON.Body.STATIC,
            shape: new CANNON.Plane(),
        });
        //planeBody.position = new CANNON.Vec3(0, 1, 0)
        planeBody.quaternion.setFromEuler(-Math.PI / 2, 0, 0); // make it face up
        planeBody.receiveShadow = true;
        this.world.addBody(planeBody);
        //planeBody.mass = 1000;
        //planeBody.updateMassProperties();
        /*  setTimeout(() => {
             //planeBody.isTrigger = true
            planeBody.velocity.set(0, 0, 0);
             planeBody.angularVelocity.set(0, 0, 0);
        }, 1000);*/
    }
}
