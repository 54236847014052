export default [
    {
        type: 'textures',
        data: [
            {
                name: 1,
                path: '/static/textures/behaviours/1-share.png',
            },
            {
                name: 2,
                path: '/static/textures/behaviours/2-reuse.png',
            },
            {
                name: 3,
                path: '/static/textures/behaviours/3-file.png',
            },
            {
                name: 4,
                path: '/static/textures/behaviours/4-photo.png',
            },
            {
                name: 5,
                path: '/static/textures/behaviours/5-cloud.png',
            },
            {
                name: 6,
                path: '/static/textures/behaviours/6-personal.png',
            },
            {
                name: 7,
                path: '/static/textures/behaviours/7-manager.png',
            },
            {
                name: 8,
                path: '/static/textures/behaviours/8-sticky.png',
            },
            {
                name: 9,
                path: '/static/textures/behaviours/9-multifactor.png',
            },
            {
                name: 10,
                path: '/static/textures/behaviours/10-regular.png',
            },
            {
                name: 11,
                path: '/static/textures/behaviours/11-alert.png',
            },
            {
                name: 12,
                path: '/static/textures/behaviours/12-random.png',
            },
            {
                name: 13,
                path: '/static/textures/behaviours/13-smart.png',
            },
            {
                name: 14,
                path: '/static/textures/behaviours/14-screen.png',
            },
            {
                name: 'spiderTexture',
                path: '/static/textures/spider_provisional.jpg',
            },
            {
                name: 'sphereTexture',
                path: '/static/textures/protagonist.png',
            },
        ],
    },

    {
        type: 'audio',
        data: [
            {
                name: 'positiveBlip',
                path: '/static/audio/positive_blip.mp3',
            },
        ],
    },
    {
        type: 'models',
        data: [
            {
                name: 'behaviour',
                path: '/static/models/behaviour.gltf',
            },
            {
                name: 'obelisk',
                path: '/static/models/obelisk.gltf',
            },
            {
                name: 'spider',
                path: '/static/models/Spider_rigged_3.glb',
            },
        ],
    },
    {
        type: 'behaviourObelisks',
        data: [
            {
                id: 1,
                content: 'Share your password with someone you know, it will come handy in case you forget.',
                good: false,
            },
            {
                id: 2,
                content: 'Reuse the same password across multiple accounts to make your life easier.',
                good: false,
            },
            {
                id: 3,
                content: "Save your password in a file on your device. You can't always trust your memory!",
                path: 'static/textures/behaviours/3-file.png',
                good: false,
            },
            {
                id: 4,
                content: 'Save your password as an image in your photo album, cause an image speaks a thousand words.',
                good: false,
            },
            {
                id: 5,
                content: 'Save your password as a file on a cloud storage, so you can access it from anywhere.',
                good: false,
            },
            {
                id: 6,
                content: 'Use personal details in your passwords to make it personalised.',
                good: false,
            },
            {
                id: 7,
                content: 'Use a password manager application.',
                good: true,
            },
            {
                id: 8,
                content: 'Save your password on a sticky note on your desktop.',
                good: false,
            },
            {
                id: 9,
                content: 'Enable multi-factor authentication on your accounts.',
                good: true,
            },
            {
                id: 10,
                content: 'Update your passwords frequently and regularly.',
                good: true,
            },
            {
                id: 11,
                content: 'Change your password when you receive a notification that an account was compromised.',
                good: true,
            },
            {
                id: 12,
                content: 'Use long, strong passwords, such as the three-random-words format.',
                good: true,
            },
            {
                id: 13,
                content: 'Change "smart" device factory default passwords.',
                good: true,
            },
            {
                id: 14,
                content: 'Use screen locks on your devices.',
                good: true,
            },
        ],
    },
];
/*  {
        name: 'sphereTexture',
        type: 'texture',
        path: 'static/textures/purple_texture.jpg',
      },
      {
        name: 'floorTexture',
        type: 'texture',
        path: 'static/textures/floor_texture.jpg',
      },
      {
        name: 'glassTexture',
        type: 'texture',
        path: 'static/textures/glass_texture.png',
      },*/

/*  {
  name: 'positiveBlip',
  type: 'audio',
  path: 'static/audio/positive_blip.mp3',
},*/
